<template>
    <div class="container-fluid m-2 p-3">
         <!-- <div class=" d-flex justify-content-between ">

            <p class="logo display-3">Chats</p>
            <div class="d-flex">
                <i class="logo fa-brands fa-whatsapp "></i>
                <p class="ms-2 display-4 d-flex justify-content-center" style="margin-bottom:0;">Whatsapp</p>
            </div>
        </div> -->
        <!-- Contenido de los chats -->
         
        <div class="row pb-1 pt-1" style="height: 100%;">
            <div class="col-4">
                
                <div v-if="ready" class="chats " style="overflow-y: scroll; " >

                    <button @click="getConversations()" class=" ms-3 mt-2 mb-2 btn-reload">
                    <i class="fa-solid fa-rotate-right"></i>
                </button>
                    <div v-for="(conversation) in conversaciones" :key="conversation.id">
                        <div :id="'conv-'+conversation.id" class="conversation row ms-0 chat d-flex justify-content-center align-items-center p-2"
                         @click="selectConversation(conversation.id, conversation)">
                            <div class="conversation-click"></div>
                        <div class="col-2">
                            <!-- Aquí puedes poner una imagen de perfil, por ejemplo -->
                            <div class="circle-profile d-flex justify-content-center align-items-center">
                                <i class="fa-solid fa-user"></i>
                            </div>
                        </div>
                        <div class="col-8">
                            <!-- Mostrar el nombre del participante (puedes usar `Participante1` o `Participante2`) -->
                            <p v-if="!conversation.cliente.nombres">{{ conversation.participante1 }}</p>
                            <p v-else> {{ conversation.cliente.nombres }} {{ conversation.cliente.apellidos }}</p>
                            <!-- O, si prefieres el otro participante: {{ conversation.Participante2 }} -->
                            <!-- Mostrar el último mensaje -->
                            <p class="last-message">{{ conversation.lastMessage?.message.slice(0, 22) + '...' || 'No hay mensajes' }}</p>

                        </div>
                        <div class="col-2 p-1">
                            <!-- Mostrar la hora del último mensaje -->
                            <p>{{ conversation.updatedAt ? formatTime(conversation.updatedAt) : (conversation.createdAt
                                ? formatTime(conversation.createdAt ):'' ) }}</p>
                        </div>
                    </div>
                    </div>
                    



                </div>
                <div v-else class="chats d-flex justify-content-center pt-3">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
            <div class="col-8 d-flex align-items-center" style="height: 78vh;">
                <ChatView :key="`${conversationId}`" :conversation-id="conversationId" style="height: 100%;" :conversacion="conversation" />
            </div>


        </div>

    </div>
</template>

<script>
import Swal from 'sweetalert2';
import Security from '../security';
import ChatView from './ChatView.vue';

export default {
    components: {
        ChatView
    },
    data() {
        return {
            conversaciones: [],
            conversationId: 0,
            conversation: {},
            ready: false,
        }
    },
    
    beforeMount() {
        this.getConversations()

    },
    methods: {
        getConversations(){
            fetch(
            process.env.VUE_APP_API_URL +
            "/admin/whatsapp/get-conversations",
            Security.requestOptions({})
        )
            .then((response) => response.json())
            .then((data) => {
                if (data.error) {
                    Swal.fire({
                        icon: "error",
                        title: data.message + data.error,
                        showConfirmButton: false,
                        timer: 1500,
                    });

                } else {

                    this.conversaciones = data.data.conversations

                    console.log(this.conversaciones);
                    this.ready = true



                }
            });
        },
        formatTime(timestamp) {
            const date = new Date(timestamp);
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
        },
        selectConversation(id, conversation){
            if (document.getElementById('conv-' + this.conversationId)) {
                document.getElementById('conv-' + this.conversationId).classList.remove("chat-active");
            }
            this.conversationId = id
            this.conversation = conversation

            document.getElementById('conv-' + id).classList.add("chat-active");
        }
    }
}
</script>

<style scoped>
.container-fluid {
    height: 85vh;
    border-radius: 15px;
    background: #606c88;
    background: -webkit-linear-gradient(0deg, #606c88 0%, #3f4c6b 100%);
    background: linear-gradient(0deg, #606c88 0%, #3f4c6b 100%);
}

.logo {
    font-size: 35px !important;
    color: white;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center
}

.chats {
    height: 78vh;
    background-color: #34405c;
    /* border: 2px solid #727294; Borde de 2px de grosor y color verde */
    border-radius: 7px;
    /* Esquinas redondeadas */
}

.chat {
    color: white;
    font-size: 14px;
    width: 100%;
    background-color: #34405c;
}

p {
    color: white;
}

.chat:hover {
    background-color: #6f788c91;
}
.chat:active {
    background-color: #5e667791;
}
.chat-active {
    background-color: #4b515d91;
}

.circle-profile {
    color: #7389c1;

    width: 40px;
    /* Ancho del círculo */
    height: 40px;
    /* Alto del círculo */
    border-radius: 50%;
    /* Hacer las esquinas redondeadas al 50% */
    background-color: #3498db52;
    /* Color de fondo, puedes cambiarlo */
}

.last-message {
    
    color: #88898b;
    margin: 0;
}

.conversation{
    cursor: pointer;
} 


/* Scroll */

/* Scroll personalizado para navegadores basados en Webkit */
::-webkit-scrollbar {
  width: 12px; /* Ancho del scroll vertical */
  height: 12px; /* Alto del scroll horizontal */
}

::-webkit-scrollbar-track {
  background: #ffffff00; /* Fondo del track */
  border-radius: 10px; /* Bordes redondeados */
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #2E3951, #2575fc); /* Color degradado del scroll */
  border-radius: 10px; /* Bordes redondeados */
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #2E3951, #1e6ae6); /* Cambio de color al pasar el mouse */
}

/* Scroll personalizado para Firefox */
* {
  scrollbar-width: thin; /* Grosor del scrollbar */
  scrollbar-color: #2E3951 #ffffff1b; /* Color del thumb y track */
}

/* Scroll dentro de un contenedor específico */
.scroll-container {
  /* max-height: 400px; Altura máxima del contenedor */
  overflow-y: auto; /* Activar scroll vertical */
  /* padding: 10px; Espaciado interno */
  /* border: 1px solid #ddd; Bordes del contenedor */
  border-radius: 8px; /* Bordes redondeados */
  /* background-color: #fff; Fondo del contenedor */
  width: 100%;
  margin: 0;
}
.btn-reload{
    margin: 0;
    border-radius: 5px;
    border: 0px;
    background-color: #3c4763;
    padding-inline: 15px;
    padding-block: 5px;
}
.btn-reload:hover{

    background-color: #434e6b;

}
.btn-reload:active{

    background-color: #515e81;

}
</style>