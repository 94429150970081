<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable no-unused-vars -->

<template>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-12 grid-margin">
          <div class="card">
            <div class="card-body">
              <form-tag @ClienteEditEvent="submitHandler" name="clienteForm" event="clienteEditEvent" id="cliente-form">
                <div class="row mb-3 d-flex justify-content-center align-item-center">


                  <div class="col-4 text-center">
                    <h4 v-if="validarGrupo" style="font-size: 20px" class="ms-2 mt-3 mb-4">
                      {{ noIntegregante }} Integrante
                    </h4>
                    <h4 v-else style="font-size: 20px" class="ms-2 mt-3 mb-4">
                      Nueva Solicitud de Crédito
                    </h4>
                  </div>
                  <div class="col-8 d-flex align-items-start justify-content-end" style="height: 90%">
                    <input v-if="this.idSolicitud <= 0" type="submit" class="btn btn-dark" value="Guardar" />
                    <div class="btnOptionSolicitud" v-else>
                      <button @click="handleImprimir()" type="button" class="btn btn-success">
                        Exportar Plan De Pago <i class="fa-solid fa-download"></i>
                      </button>
                      <button @click="exportarSolicitudPDF()" type="button" class="btn btn-secondary">
                        Exportar Solicitud <i class="fa-solid fa-download"></i>
                      </button>
                      <button v-if="this.solicitud.estado === 'Aprobado' || this.solicitud.estado === 'Desembolsado'"
                        @click="exportacionContratos(this.solicitud, this.cuotaSolicitud, this.comisionDesembolso,this.comisionAsistenciaTecnica)" type="button"
                        class="btn btn-twitter">
                        Exportar Contrato <i class="fa-solid fa-download"></i>
                      </button>
                      <button v-if="this.solicitud.estado === 'Aprobado' || this.solicitud.estado === 'Desembolsado'"
                        @click="exportacionPagares(this.solicitud, this.fechaVencimiento, this.comisionDesembolso,this.comisionAsistenciaTecnica)" type="button"
                        class="btn btn-twitter">
                        Exportar Pagaré <i class="fa-solid fa-download"></i>
                      </button>
                      <button v-if="this.solicitud.estado === 'Aprobado' || this.solicitud.estado === 'Desembolsado'"
                        @click="exportacionFianza(this.solicitud, this.fechaVencimiento, this.comisionDesembolso,this.comisionAsistenciaTecnica)" type="button"
                        class="btn btn-twitter">
                        Exportar Avales Solidarios <i class="fa-solid fa-download"></i>
                      </button>

                      <button v-if="this.solicitud.estado !== 'Aprobado' && this.solicitud.estado !== 'Desembolsado'"
                        type="submit" class="btn btn-primary">
                        Actualizar <i class="fa-solid fa-floppy-disk"></i>
                      </button>
                      <button v-if="this.userRoles[0].some(role => role === 'Modo Thanos')" type="submit"
                        class="btn btn-primary">
                        Actualizar <i class="fa-solid fa-floppy-disk"></i>
                      </button>
                      <div v-if="this.Asesor !== true">
                        <button type="button" v-if="this.solicitud.estado === 'Pendiente'" class="btn btn-warning"
                          data-bs-toggle="modal" data-bs-target="#estadoSolicitud">
                          Estado {{ this.solicitud.estado }}
                        </button>
                        <button type="button" v-else-if="(this.solicitud.estado === 'Aprobado') && (this.Admin || this.Coordinacion)" class="btn btn-success"
                        data-bs-toggle="modal" data-bs-target="#estadoSolicitud" >
                          Estado {{ this.solicitud.estado }}
                        </button>
                        <button type="button" v-else-if="this.solicitud.estado === 'Aprobado'" class="btn btn-success"
                         disabled>
                          Estado {{ this.solicitud.estado }}
                        </button>
                        <button type="button" v-else-if="this.solicitud.estado === 'Rechazado' && (this.Admin || this.Coordinacion)" class="btn btn-danger"
                          data-bs-toggle="modal" data-bs-target="#estadoSolicitud">
                          Estado {{ this.solicitud.estado }}
                        </button>
                        <button type="button" v-else-if="this.solicitud.estado === 'Rechazado'" class="btn btn-danger"
                          data-bs-toggle="modal" data-bs-target="#estadoSolicitud" disabled>
                          Estado {{ this.solicitud.estado }}
                        </button>
                        <button type="button" v-else-if="this.solicitud.estado === 'Desembolsado'" class="btn btn-dark"
                          disabled>
                          Estado {{ this.solicitud.estado }}
                        </button>
                      </div>
                    </div>



                  </div>

                </div>
                <div class="accordion" id="accordionPanelsStayOpenExample">
                  <!-- Aqui va datos Solicitante -->
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne">
                        <div class="col-6">Datos Solicitante</div>
                        <div id="validar-1" class="d-flex justify-content-end me-3" style="width: 100%"></div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                      aria-labelledby="panelsStayOpen-headingOne">
                      <div class="accordion-body">
                        <div class="row">
                          <div v-if="this.idSolicitud <= 0" class="col-12">
                            <p>Busca por DNI</p>
                            <input v-model="dni" @input="formatInput" placeholder="Ejemplo: 0801-1998-02195"
                              class="form-control" style="height: 20px" type="text" minlength="15" maxlength="15"
                              id="dni" required />
                            <router-link class="nav-link w-100" to="/solicitante/0">Crear Nuevo
                              Beneficiario</router-link>
                          </div>
                          <div v-else class="col-4 d-flex justify-content-center align-item-center"></div>
                          <div v-if="this.cliente" class="col-12">
                            <div class="row mt-3 mb-3">
                              <div class="col-sm-8">
                               <strong>Nombre: </strong>  {{ this.cliente.nombres }} {{ this.cliente.apellidos }}
                              </div>
                              <div class="col-sm-4"> <strong>DNI: </strong> {{ this.cliente.dni }}</div>
                            </div>
                            <div class="row mt-3 mb-3">
                              <div class="col-sm-4">
                                <strong>No Teléfono: </strong>{{ this.cliente.celular }}
                              </div>
                              <div class="col-sm-4"><strong>No Teléfono Negocio: </strong> {{ this.cliente.telefono }}</div>
                              <div class="col-sm-4">
                                <strong>RTN:</strong> {{ this.cliente.rtn }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Aqui va condiciones Credito -->

                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingTwoMore">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwoMore" aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwoMore">
                        <div class="col-6">
                          Condiciones del Crédito
                        </div>
                        <div id="validar-2" class="d-flex justify-content-end me-3" style="width: 100%">
                          {{ this.solicitud.producto }}
                        </div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwoMore" class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingTwoMore">
                      <div class="accordion-body">

                        <!-- Plan pagos -->
                        <div id="contenedor-plan">
                          <div class="header">
                            <h3>Solicitud de préstamo</h3>
                          </div>

                          <div name="frmPrestamo" id="frmPrestamo">
                            <div v-if="creditrabajo2()" class="control d-flex flex-column">
                              <label class="mb-2"><strong>Monto Credito:</strong> {{
                                this.formatoNumero(this.solicitud.monto) }} {{
                                  this.moneda }}</label>

                              <label for="interes"> <strong> Interés (anual):</strong> {{ this.solicitud.interes }}
                                % ({{ this.formatoNumero(this.montoInteres) }} {{ this.moneda }})</label>
                              <label for="interes"> <strong> Interés (mensual):</strong> {{ this.solicitud.interes / 12
                                }}
                                % </label>
                              <label for="interes"> <strong> Comisión por desembolso: </strong> {{
                                this.comisionDesembolso }} % ({{ this.formatoNumero(this.totalComisionDesembolso) }} {{
                                  this.moneda
                                }})</label>
                              <label for="interes"><strong>Comisión por asistencia técnica: </strong>{{
                                this.comisionAsistenciaTecnica }} % ({{
                                  this.formatoNumero(this.totalComisionAsistenciaTecnica) }} {{
                                  this.moneda }})</label>
                              <label for="interes"><strong>Tasa de seguridad: </strong> {{ this.tasaSeguridad }}
                                % ({{ this.formatoNumero(this.totalTasaSeguridad) }} {{ this.moneda }})</label>

                            </div>
                            <div class="control">
                              <label for="fecha">Fecha: </label>
                              <input type="date" :required="(this.modoThanos===true)?false:true" class="form-control mt-2 mb-2"
                                v-model="solicitud.fechaPago" name="fecha" id="fecha" placeholder="fecha">
                            </div>
                            <div v-if="creditrabajo2()" class="control">

                              <label for="monto">Monto a Desembolsar: </label>
                              <!-- <input type="number"  class="form-control" v-model="solicitud.monto"
                                name="monto" id="monto" placeholder="monto" min="500.00" max="300000.00"> -->
                              {{ this.solicitud.montoDesembolsar }}
                              <input type="text" :required="(this.modoThanos===true)?false:true"  class="form-control" v-model="montoDesembolsar"
                                name="montoDesembolsar" id="montoDesembolsar" placeholder="monto"
                                pattern="\d+(\.\d{1,2})?" min="500" max="300000" @blur="onBlur">

                            </div>
                            <div v-else class="control">

                              <label for="monto">Monto Aprobado: </label>
                              <!-- <input type="number"  class="form-control" v-model="solicitud.monto"
                                name="monto" id="monto" placeholder="monto" min="500.00" max="300000.00"> -->
                              <input type="text" :required="(this.modoThanos===true)?false:true"  class="form-control" v-model="solicitud.monto"
                                name="monto" id="monto" placeholder="monto" pattern="\d+(\.\d{1,2})?" min="500"
                                max="300000">
                            </div>

                            <div class="control">
                              <label for="periodo">Periodo de pago:</label>

                              <select v-if="creditrabajo2()" v-model="solicitud.frecuenciaPago" :required="(this.modoThanos===true)?false:true" 
                                class="form-control" name="periodo" id="periodo" disabled>
                                <option value="semanal">semanal</option>

                              </select>
                              <select v-else v-model="solicitud.frecuenciaPago" :required="(this.modoThanos===true)?false:true"  class="form-control"
                                name="periodo" id="periodo">
                                <option value="semanal">semanal</option>
                                <option value="quincenal">quincenal</option>
                                <option value="mensual">mensual</option>
                                <option value="al vencimiento">Al Vencimiento</option>
                                <option value="trimestral">trimestral</option>
                                <option value="semestral">semestral</option>
                                <option value="anual">anual</option>
                              </select>
                            </div>

                            <div v-if="!(creditrabajo2())" class="control">
                              <label for="interes">Interés (anual): </label>
                              <input type="number" class="form-control" v-model="solicitud.interes" :required="(this.modoThanos===true)?false:true" 
                                name="interes" id="interes" placeholder="interés" min="5" max="100" step=".01">
                            </div>

                            <!-- Plazo -->

                            <div v-if="creditrabajo2()" class="control">
                              <label for="plazo">Plazo: (Mensual) </label>
                              <input type="number" class="form-control" placeholder="Plazo Pago" :required="(this.modoThanos===true)?false:true" 
                                name="plazo" v-model="solicitud.plazoPago" id="plazo" min="1" max="6" step="1">
                              <p v-if="creditrabajo2() && (solicitud.plazoPago < 1 || solicitud.plazoPago > 6)"
                                class="text-danger">El plazo debe estar entre el rango de 1 a 6 meses</p>
                            </div>

                            <div
                              v-else-if="this.solicitud.frecuenciaPago == 'trimestral' || this.solicitud.frecuenciaPago == 'semestral' || this.solicitud.frecuenciaPago == 'anual'"
                              class="control">
                              <label for="periodo">Periodo de pago:</label>
                              <select v-model="solicitud.plazoPago" :required="(this.modoThanos===true)?false:true"  class="form-control" name="plazo"
                                id="plzao">
                                <option value="12">12 Meses</option>
                                <option value="24">24 Meses</option>
                                <option value="36">36 Meses</option>
                                <option value="48">48 Meses</option>
                                <option value="60">60 Meses</option>
                                <option value="72">72 Meses</option>
                              </select>
                            </div>
                            <div v-else class="control">
                              <label for="plazo">Plazo: (Mensual) </label>
                              <input type="number" class="form-control" placeholder="Plazo Pago" :required="(this.modoThanos===true)?false:true" 
                                name="plazo" v-model="solicitud.plazoPago" id="plazo" min="1" step="1">
                            </div>

                            <!-- Button trigger modal -->
                            <button type="button" class="btn btn-primary" @click="ejecutarPlanPagos()"
                              data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                              Ver Plan de pago
                            </button>

                          </div>
                        </div>

                        <!-- Modal -->

                        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                          tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-scrollable modal-xl">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h1 class="modal-title fs-5" id="staticBackdropLabel">Plan de Pagos</h1>
                                <button type="button" class="btn-close" data-bs-dismiss="modal"
                                  aria-label="Close"></button>
                              </div>
                              <div class="modal-body">
                                <div id="contenedorTabla">
                                  <div class="header">
                                    <h2>Tabla de amortizaciones</h2>
                                  </div>
                                  <table class="table table-striped table-hover">

                                    <thead class="table-dark">
                                      <tr>
                                        <th scope="col">No. Cuota</th>
                                        <th scope="col">Fecha de Pago</th>
                                        <th scope="col">Cuota Total</th>
                                        <th scope="col">Interés</th>
                                        <th scope="col">Capital</th>
                                        <th scope="col">Saldo</th>
                                      </tr>
                                    </thead>
                                    <tbody id="tablaPlanPagos">

                                    </tbody>
                                  </table>
                                  <div id="amortizaciones">

                                  </div>
                                </div>
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                              </div>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>

                  <!-- Aqui va destino Credito -->
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree">
                        <div class="col-6">
                          Destino del Crédito
                        </div>
                        <div id="validar-3" class="d-flex justify-content-end me-3" style="width: 100%">

                        </div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingThree">
                      <div class="accordion-body">
                        <!-- Acorddion Body -->
                        <div class="form-group row">
                          <div class="col-sm-9 ms-4">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" id="emprenderCheckbox"
                                v-model="solicitud.destinoCreditos" value="Emprender un Negocio" />
                              <label class="form-check-label" for="emprenderCheckbox">
                                Emprender un Negocio
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" id="maquinariaCheckbox"
                                v-model="solicitud.destinoCreditos" value="Comprar maquinaria y equipo" />
                              <label class="form-check-label" for="maquinariaCheckbox">
                                Comprar maquinaria y equipo
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" id="reabastecerCheckbox"
                                v-model="solicitud.destinoCreditos" value="Reabastecer el negocio" />
                              <label class="form-check-label" for="reabastecerCheckbox">
                                Reabastecer el negocio
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" id="otrasCheckbox"
                                v-model="solicitud.destinoCreditos" value="Otras" />
                              <label class="form-check-label" for="otrasCheckbox">
                                Otras
                              </label>
                            </div>
                          </div>
                        </div>


                        <!-- SUCURSAL -->
                        <div class="col-md-6">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">sucursal</label>
                            <div class="col-sm-9 d-flex align-items-center">
                              <!--  <text-input v-model="cliente.sucursal" type="text" :value="cliente.sucursal"
                                  name="sucursal"></text-input> -->

                              <select v-model="solicitud.sucursal" id="sucursal" name="sucursal" class="form-select"
                                :required="(this.modoThanos===true)?false:true" >
                                <option v-for="departamentos in departamentos" :key="departamentos.idDepartamento"
                                  :value="departamentos.nombre">
                                  {{ departamentos.nombre }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>


                      </div>
                    </div>
                  </div>

                  <!-- Aqui va Plan De Inversion -->
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingfour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseFour">
                        <div class="col-6">
                          Plan de inversión
                        </div>
                        <div id="validar-4" class="d-flex justify-content-end me-3" style="width: 100%">

                        </div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingfour">
                      <div class="accordion-body">
                        <div class="row">

                          <table id="tabla" class="table-striped plan-inversión">
                            <thead>
                              <tr>
                                <th>Requerimiento</th>
                                <th>Descripción</th>
                                <th>Valor</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <input type="text" class="input-plan" id="req1" placeholder="Escribe el requerimiento"
                                    v-model="solicitud.planInversion.req1" />
                                </td>
                                <td>
                                  <input type="text" class="input-plan" id="desc1" placeholder="Escribe la descripción"
                                    v-model="solicitud.planInversion.desc1" />
                                </td>
                                <td>
                                  <input type="text" class="valor input-plan" id="val1" placeholder="0"
                                    v-model="solicitud.planInversion.val1" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input type="text" class="input-plan" id="req2" placeholder="Escribe el requerimiento"
                                    v-model="solicitud.planInversion.req2" />
                                </td>
                                <td>
                                  <input type="text" class="input-plan" id="desc2" placeholder="Escribe la descripción"
                                    v-model="solicitud.planInversion.desc2" />
                                </td>
                                <td>
                                  <input type="text" class="valor input-plan" id="val2" placeholder="0"
                                    v-model="solicitud.planInversion.val2" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input type="text" class="input-plan" id="req3" placeholder="Escribe el requerimiento"
                                    v-model="solicitud.planInversion.req3" />
                                </td>
                                <td>
                                  <input type="text" class="input-plan" id="desc3" placeholder="Escribe la descripción"
                                    v-model="solicitud.planInversion.desc3" />
                                </td>
                                <td>
                                  <input type="text" class="valor input-plan" id="val3" placeholder="0"
                                    v-model="solicitud.planInversion.val3" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input type="text" class="input-plan" id="req4" placeholder="Escribe el requerimiento"
                                    v-model="solicitud.planInversion.req4" />
                                </td>
                                <td>
                                  <input type="text" class="input-plan" id="desc4" placeholder="Escribe la descripción"
                                    v-model="solicitud.planInversion.desc4" />
                                </td>
                                <td>
                                  <input type="text" class="valor input-plan" id="val4" placeholder="0"
                                    v-model="solicitud.planInversion.val4" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input type="text" class="input-plan" id="req5" placeholder="Escribe el requerimiento"
                                    v-model="solicitud.planInversion.req5" />
                                </td>
                                <td>
                                  <input type="text" class="input-plan" id="desc5" placeholder="Escribe la descripción"
                                    v-model="solicitud.planInversion.desc5" />
                                </td>
                                <td>
                                  <input type="text" class="valor input-plan" id="val5" placeholder="0"
                                    v-model="solicitud.planInversion.val5" />
                                </td>

                              </tr>

                              <tr v-for="(fila, index) in solicitud.filas" :key="index">
                                <!-- Campos de cada fila -->
                                <td>
                                  <input type="text" placeholder="Escribe el requerimiento" class="input-plan"
                                    v-model="solicitud.filas[index].requerimiento" />
                                </td>
                                <td>
                                  <input type="text" placeholder="Escribe la descripción" class="input-plan"
                                    v-model="solicitud.filas[index].descripcion" />
                                </td>
                                <td>
                                  <input type="text" placeholder="0" class="input-plan"
                                    v-model="solicitud.filas[index].valor" />
                                </td>
                              </tr>
                              <tr>
                                <td>Total</td>
                                <td id="total" class="">0</td>
                                <td>{{ sumaTablaInverciones }} lps</td>
                              </tr>
                            </tbody>
                          </table>
                          <button @click="quitarFila" type="button" class="btn btn-primary" style="color: white;">Quitar
                            Fila -</button>
                          <button @click="agregarFila" type="button" class="btn btn-primary"
                            style="color: white;">Agregar
                            Fila +</button>
                          <!-- {{this.solicitud.filas}} -->

                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Aqui va Referencias -->
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo">
                        <div class="col-6">
                          Referencias
                        </div>
                        <div id="validar-5" class="d-flex justify-content-end me-3" style="width: 100%">

                        </div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingTwo">
                      <div class="accordion-body">
                        <div id="referencias" class="d-flex flex-column"></div>

                        <div class="carta p-2 mt-3 mb-2" id="ref-1">
                          <p class="mb-2">Referencias Familiares</p>
                          <div class="row">
                            <div class="col-3 pad">
                              <label for="nom1">Nombre</label>
                              <input :required="(this.modoThanos===true)?false:true"  class="form-control fm" v-model="solicitud.referencias.nom1" type="text"
                                id="nom1" placeholder="Nombre" />
                            </div>
                            <div class="col-3 pad">
                              <label for="par1">Parentesco</label>
                              <!-- <input required require="true" class="form-control fm" v-model="solicitud.referencias.par1"
                                type="text" id="par1" placeholder="Parentesco" /> -->
                              <select v-model="solicitud.referencias.par1" id="par1" class="form-select" :required="(this.modoThanos===true)?false:true" 
                                aria-label="Default select example">
                                <option value="Padre">Padre</option>
                                <option value="Madre">Madre</option>
                                <option value="Hermano(a)">Hermano(a)</option>
                                <option value="Tío(a)">Tío(a)</option>
                                <option value="Abuelo(a)">Abuelo(a)</option>
                                <option value="Sobrino(a)">Sobrino(a)</option>
                                <option value="Hijo(a)">Hijo(a)</option>
                                <option value="Primo(a)">Primo(a)</option>
                                <option value="Suegro(a)">Suegro(a)</option>
                                <option value="Cuñado(a)">Cuñado(a)</option>
                                <option value="Nuera">Nuera</option>
                                <option value="Yerno">Yerno</option>
                                <option value="Esposo(a)">Esposo(a)</option>

                              </select>
                            </div>
                            <div class="col-3 pad">
                              <label for="dir1">Dirección</label>
                              <input :required="(this.modoThanos===true)?false:true"  require="true" class="form-control fm"
                                v-model="solicitud.referencias.dir1" type="text" id="dir1" placeholder="Dirección" />
                            </div>
                            <div class="col-3 pad">
                              <label for="cel1">Celular</label>
                              <input :required="(this.modoThanos===true)?false:true"  require="true" class="form-control fm"
                                v-model="solicitud.referencias.cel1" type="number" id="cel1" placeholder="Celular" />
                            </div>
                          </div>
                        </div>
                        <div class="carta p-2 mt-3 mb-2" id="ref-2">
                          <!-- <p class="mb-2">Referencia 2</p> -->
                          <div class="row">
                            <div class="col-3 pad">
                              <label for="nom2">Nombre</label>
                              <input :required="(this.modoThanos===true)?false:true"  require="true" class="form-control fm"
                                v-model="solicitud.referencias.nom2" type="text" id="nom2" placeholder="Nombre" />
                            </div>
                            <div class="col-3 pad">
                              <label for="par2">Parentesco</label>
                              <!-- <input :required="(this.modoThanos===true)?false:true"  require="true" class="form-control fm" v-model="solicitud.referencias.par2"
                                type="text" id="par2" placeholder="Parentesco" /> -->
                              <select v-model="solicitud.referencias.par2" id="par2" :required="(this.modoThanos===true)?false:true"  class="form-select"
                                aria-label="Default select example">
                                <option value="Padre">Padre</option>
                                <option value="Madre">Madre</option>
                                <option value="Hermano(a)">Hermano(a)</option>
                                <option value="Tío(a)">Tío(a)</option>
                                <option value="Abuelo(a)">Abuelo(a)</option>
                                <option value="Hijo(a)">Hijo(a)</option>
                                <option value="Primo(a)">Primo(a)</option>
                                <option value="Suegro(a)">Suegro(a)</option>
                                <option value="Cuñado(a)">Cuñado(a)</option>
                                <option value="Nuera">Nuera</option>
                                <option value="Yerno">Yerno</option>
                                <option value="Esposo(a)">Esposo(a)</option>

                              </select>
                            </div>
                            <div class="col-3 pad">
                              <label for="dir2">Dirección</label>
                              <input :required="(this.modoThanos===true)?false:true"  require="true" class="form-control fm"
                                v-model="solicitud.referencias.dir2" type="text" id="dir2" placeholder="Dirección" />
                            </div>
                            <div class="col-3 pad">
                              <label for="cel2">Celular</label>
                              <input :required="(this.modoThanos===true)?false:true"  require="true" class="form-control fm"
                                v-model="solicitud.referencias.cel2" type="number" id="cel2" placeholder="Celular" />
                            </div>
                          </div>
                        </div>
                        <div class="carta p-2 mt-3 mb-2" id="ref-3">
                          <p class="mb-2">Referencias Personales</p>
                          <div class="row">
                            <div class="col-3 pad">
                              <label for="nom3">Nombre</label>
                              <input :required="(this.modoThanos===true)?false:true"  require="true" class="form-control fm"
                                v-model="solicitud.referencias.nom3" type="text" id="nom3" placeholder="Nombre" />
                            </div>
                            <div class="col-3 pad">
                              <label for="par3">Parentesco</label>
                              <!-- <input :required="(this.modoThanos===true)?false:true"  require="true" class="form-control fm" v-model="solicitud.referencias.par3"
                                type="text" id="par3" placeholder="Parentesco" /> -->
                              <select v-model="solicitud.referencias.par3" :required="(this.modoThanos===true)?false:true"  class="form-select" id="par3"
                                aria-label="Default select example">
                                <option value="Amigo(a)">Amigo(a)</option>
                                <option value="Compañero de Trabajo">Compañero de Trabajo</option>
                                <option value="Conocido(a)">Conocido(a)</option>

                              </select>
                            </div>
                            <div class="col-3 pad">
                              <label for="dir3">Dirección</label>
                              <input :required="(this.modoThanos===true)?false:true"  require="true" class="form-control fm"
                                v-model="solicitud.referencias.dir3" type="text" id="dir3" placeholder="Dirección" />
                            </div>
                            <div class="col-3 pad">
                              <label for="cel3">Celular</label>
                              <input :required="(this.modoThanos===true)?false:true"  require="true" class="form-control fm"
                                v-model="solicitud.referencias.cel3" type="number" id="cel3" placeholder="Celular" />
                            </div>
                          </div>
                        </div>
                        <div class="carta p-2 mt-3 mb-2" id="ref-4">
                          <!-- <p class="mb-2">Referencia 4</p> -->
                          <div class="row">
                            <div class="col-3 pad">
                              <label for="nom4">Nombre</label>
                              <input :required="(this.modoThanos===true)?false:true"  require="true" class="form-control fm"
                                v-model="solicitud.referencias.nom4" type="text" id="nom4" placeholder="Nombre" />
                            </div>
                            <div class="col-3 pad">
                              <label for="par4">Parentesco</label>
                              <!-- <input :required="(this.modoThanos===true)?false:true"  require="true" class="form-control fm" v-model="solicitud.referencias.par4"
                                type="text" id="par4" placeholder="Parentesco" /> -->
                              <select v-model="solicitud.referencias.par4" :required="(this.modoThanos===true)?false:true"  class="form-select" id="par4"
                                aria-label="Default select example">
                                <option value="Amigo(a)">Amigo(a)</option>
                                <option value="Compañero de Trabajo">Compañero de Trabajo</option>
                                <option value="Conocido(a)">Conocido(a)</option>
                              </select>
                            </div>
                            <div class="col-3 pad">
                              <label for="dir4">Dirección</label>
                              <input :required="(this.modoThanos===true)?false:true"  require="true" class="form-control fm"
                                v-model="solicitud.referencias.dir4" type="text" id="dir4" placeholder="Dirección" />
                            </div>
                            <div class="col-3 pad">
                              <label for="cel4">Celular</label>
                              <input :required="(this.modoThanos===true)?false:true"  require="true" class="form-control fm"
                                v-model="solicitud.referencias.cel4" type="number" id="cel4" placeholder="Celular" />
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <!-- Aqui va Grupo Solidario -->
                  <!-- <grupoSolidarioVue  v-if="validarGrupo"/> -->

                  <!-- Aqui va Evaluacion de Calidad -->
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingsix">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSix" aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseSix">
                        <div class="col-6">
                          Evaluación de Calidad Y Condiciones de Vida
                        </div>
                        <div id="validar-6" class="d-flex justify-content-end me-3" style="width: 100%">

                        </div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseSix" class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingsix">
                      <div class="accordion-body">
                        <div class="row">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">¿Tiempo de residir en la vivienda?</label>
                            <div class="col-sm-9 d-flex align-items-center">
                              <input :required="(this.modoThanos===true)?false:true"  id="tiempoVivienda" require="true" v-model="solicitud.tiempoVivienda"
                                type="text" class="form-control" />
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="form-group row">
                            <label class="col-sm-3 col-form-label">Material que predomina en vivienda</label>
                            <div class="col-sm-9 d-flex align-items-center">
                              <select :required="(this.modoThanos===true)?false:true"  id="materialVivienda" require="true" v-model="solicitud.materialVivienda"
                                class="form-select" aria-label="Default select example">
                                <option value="Ladrillo">Ladrillo</option>
                                <option value="Adobe">Adobe</option>
                                <option value="Madera">Madera</option>
                                <option value="Bloque">Bloque</option>
                                <option value="Otros">Otros</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          Servicios públicos que tiene en la vivienda
                          <div class="form-group row">
                            <div class="col-sm-9 ms-4">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="energia electrica"
                                  v-model="solicitud.serviciosPublico" id="energiaElectrica" require="true" />
                                <label class="form-check-label" for="energiaElectrica">
                                  Energía Eléctrica
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="red de agua potable"
                                  v-model="solicitud.serviciosPublico" id="aguaPotable" require="true" />
                                <label class="form-check-label" for="aguaPotable" require="true">
                                  Red de agua potable
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="red de aguas negras"
                                  v-model="solicitud.serviciosPublico" id="aguasNegras" require="true" />
                                <label class="form-check-label" for="aguasNegras" require="true">
                                  Red de aguas negras
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="pozo septico"
                                  v-model="solicitud.serviciosPublico" id="pozoSeptico" require="true" />
                                <label class="form-check-label" for="pozoSeptico" require="true">
                                  Pozo Séptico/Letrina/Otro
                                </label>
                              </div>
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="telefono fijo"
                                  v-model="solicitud.serviciosPublico" id="telefonoFijo" require="true" />
                                <label class="form-check-label" for="telefonoFijo">
                                  Teléfono Fijo
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <TextInputForm  label="¿Cuántas personas habitan en la vivienda?" 
                            name="personas-vivienda" id="personasVivienda" v-model="solicitud.noPersonasVivivenda"
                            type="number" />
                          <TextInputForm require="true" label="¿Cuántas familias viven?" name="familias" id="familias"
                            v-model="solicitud.noFamilias" type="number" />
                        </div>

                        <div class="row">
                          <TextInputForm require="true" label="¿Cuántas trabajan?" name="trabajan" id="trabajan"
                            v-model="solicitud.noTrabajan" type="number" />
                        </div>

                        <div class="row">
                          <TextInputForm label="¿Cuántas son dependientes?" require="true" name="dependientes"
                            id="dependientes" v-model="solicitud.noDependientes" type="number" />
                          <TextInputForm label="¿Cuántas buscan empleo?" require="true" name="buscan-empleo"
                            id="buscanEmpleo" v-model="solicitud.noBuscanEmpleo" type="number" />
                        </div>
                      </div>
                    </div>

                  </div>

                  <!-- Aqui va Grupos Especiales -->
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingseven">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSeven" aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseSeven">
                        <div class="col-6">
                          Sector y Grupos Especiales
                        </div>
                        <div id="validar-7" class="d-flex justify-content-end me-3" style="width: 100%"></div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseSeven" class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingseven">

                      <div class="accordion-body">
                        <p class="mt-3">Sector</p> 

                        <div class=" row">
                          <div class="col-sm-6 ms-4 col-6">
                            <div class="form-check ">
                              <input class="form-check-input" type="checkbox" value="Adulto Mayor"
                                v-model="solicitud.sector" id="flexCheckDefault" />
                              <label class="form-check-label" for="flexCheckDefault">
                                Adulto Mayor
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Afrohondureños"
                                v-model="solicitud.sector" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Afrohondureños
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Discapacitados"
                                v-model="solicitud.sector" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Discapacitados
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Extema Pobreza"
                                v-model="solicitud.sector" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Extema Pobreza
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Jóvenes" v-model="solicitud.sector"
                                id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Jóvenes
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Migrante Retornado"
                                v-model="solicitud.sector" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Migrante Retornado
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Mujer Campesina"
                                v-model="solicitud.sector" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Mujer Campesina
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox"
                                value="Mujeres en Situación de Vulnerabilidad" v-model="solicitud.sector"
                                id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Mujeres en Situación de Vulnerabilidad
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Pobreza" v-model="solicitud.sector"
                                id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Pobreza
                              </label>
                            </div>
                          </div>
                        </div>
                        <!-- Grupos Especiales -->
                        <p class="mt-3">Grupos Especiales</p>

                        <div class=" row ms-2">
                          <div class="col-sm-6 col-6 ">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Pueblos Indígenas"
                                v-model="solicitud.gruposEspeciales" id="flexCheckDefault" />
                              <label class="form-check-label" for="flexCheckDefault">
                                Pueblos Indígenas
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="DINIS"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                DINIS
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Crédito del SSE"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Crédito del SSE
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Tu Banca Joven"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Tu Banca Joven
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Fundeimh"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Fundeimh
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Bici Solidaria"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Bici Solidaria
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Anmpih"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Anmpih
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Credi-Taxi"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Credi-Taxi
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Credi-Salón"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Credi-Salón
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Credi-Babería"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Credi-Babería
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Credi-Pulpería"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Credi-Pulpería
                              </label>
                            </div>
                          </div>
                          <div class="col-sm-6 col-6">
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="DINAFROH"
                                v-model="solicitud.gruposEspeciales" id="flexCheckDefault" />
                              <label class="form-check-label" for="flexCheckDefault">
                                DINAFROH
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Credi-Feria"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Credi-Feria
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Progresa"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Progresa
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Reservistas"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Reservistas
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Caja Rural"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Caja Rural
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Projoven"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Projoven
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="CICESCT"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                CICESCT
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Ayuda Solidaria"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Ayuda Solidaria
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Asonapvsidah"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Asonapvsidah
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Ciudad Mujer"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Ciudad Mujer
                              </label>
                            </div>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" value="Senaeh"
                                v-model="solicitud.gruposEspeciales" id="flexCheckChecked" />
                              <label class="form-check-label" for="flexCheckChecked">
                                Senaeh
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Grupo Solidarios -->
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOnea2">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseLast2" aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseLast2">
                        <div class="col-6">Grupo Solidario</div>
                        <div id="validar-8" class="d-flex justify-content-end me-3" style="width: 100%"></div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseLast2" class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingOne2">
                      <div class="accordion-body">

                        <div class="row">
                          <div class="form-group row">
                            <label class="col-sm-2 col-form-label">Nombre Grupo Solidario</label>
                            <div class="col-sm-9 d-flex align-items-center">
                              <input id="tiempoVivienda" v-model="solicitud.nombreGrupoSolidario" type="text"
                                class="form-control" />
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <label class="col-sm-3 col-form-label">Integrantes por DNI</label>
                          <p>Ingresa el DNI de los integrantes sin guiones</p>
                          <div class="form-group row">

                            <!-- Integrante 1 -->
                            <div class="col-sm-4 d-flex align-items-center">
                              <p class="me-3">Dni: </p>
                              <input id="integrante-gs" placeholder="integrante 1"
                                v-model="solicitud.integrantesGruposSolidario.int1" type="text" class="form-control" />
                            </div>
                            <div class="col-sm-5 d-flex align-items-center">
                              <i class="me-4">{{ this.nomInt1 }}</i>
                              <i v-if="(this.solicitud.integrantesGruposSolidario.int1 === this.solicitud.coordinadorGrupoSolidario) && (this.nomInt1)"
                                class="fa-solid fa-star"></i>
                            </div>
                            <div v-if="(this.nomInt1)" class="col-sm-3 d-flex align-items-center">
                              <button class="btn btn-primary" type="button"
                                @click="seleccionarCoordinador(solicitud.integrantesGruposSolidario.int1, 1)">Seleccionar
                                Coordinador</button>
                            </div>
                            <div class="col-sm-4 d-flex align-items-center">
                              <p class="me-3">Monto:</p>

                              <input placeholder="Monto integrante 1"
                                v-model="solicitud.integrantesGruposSolidario.monto1" type="text"
                                class="form-control" />
                            </div>
                            <div class="col-sm-4 d-flex align-items-center">
                              <p class="me-3">Cuota: </p>
                              <input placeholder="Cuota integrante 1"
                                v-model="solicitud.integrantesGruposSolidario.cuota1" type="text"
                                class="form-control" />
                            </div>

                          </div>

                          <!-- Integrante 2 -->
                          <div class="form-group row">

                            <div class="col-sm-4 d-flex align-items-center">
                              <p class="me-3">Dni: </p>
                              <input id="integrante-gs" placeholder="integrante 2"
                                v-model="solicitud.integrantesGruposSolidario.int2" type="text" class="form-control" />
                            </div>
                            <div class="col-sm-5 d-flex align-items-center">
                              <i class="me-4">{{ this.nomInt2 }}</i>
                              <i v-if="(this.solicitud.integrantesGruposSolidario.int2 === this.solicitud.coordinadorGrupoSolidario) && (this.nomInt2)"
                                class="fa-solid fa-star"></i>
                            </div>
                            <div v-if="(this.nomInt2)" class="col-sm-3 d-flex align-items-center">
                              <button class="btn btn-primary" type="button"
                                @click="seleccionarCoordinador(solicitud.integrantesGruposSolidario.int2, 2)">Seleccionar
                                Coordinador</button>
                            </div>
                            <div class="col-sm-4 d-flex align-items-center">
                              <p class="me-3">Monto:</p>
                              <input placeholder="Monto integrante 2"
                                v-model="solicitud.integrantesGruposSolidario.monto2" type="text"
                                class="form-control" />
                            </div>

                            <div class="col-sm-4 d-flex align-items-center">
                              <p class="me-3">Cuota: </p>
                              <input placeholder="Cuota integrante 2"
                                v-model="solicitud.integrantesGruposSolidario.cuota2" type="text"
                                class="form-control" />
                            </div>

                          </div>

                          <!-- Integrante 3 -->
                          <div class="form-group row">

                            <div class="col-sm-4 d-flex align-items-center">
                              <p class="me-3">Dni: </p>
                              <input id="integrante-gs" placeholder="integrante 3"
                                v-model="solicitud.integrantesGruposSolidario.int3" type="text" class="form-control" />
                            </div>
                            <div class="col-sm-5 d-flex align-items-center">
                              <i class="me-4">{{ this.nomInt3 }}</i>
                              <i v-if="(this.solicitud.integrantesGruposSolidario.int3 === this.solicitud.coordinadorGrupoSolidario) && (this.nomInt3)"
                                class="fa-solid fa-star"></i>
                            </div>
                            <div v-if="(this.nomInt3)" class="col-sm-3 d-flex align-items-center">
                              <button class="btn btn-primary" type="button"
                                @click="seleccionarCoordinador(solicitud.integrantesGruposSolidario.int3, 1)">Seleccionar
                                Coordinador</button>

                            </div>
                            <div class="col-sm-4 d-flex align-items-center">
                              <p class="me-3">Monto:</p>

                              <input placeholder="Monto integrante 3"
                                v-model="solicitud.integrantesGruposSolidario.monto3" type="text"
                                class="form-control" />
                            </div>
                            <div class="col-sm-4 d-flex align-items-center">
                              <p class="me-3">Cuota: </p>
                              <input placeholder="Cuota integrante 3"
                                v-model="solicitud.integrantesGruposSolidario.cuota3" type="text"
                                class="form-control" />
                            </div>
                          </div>
                          <!-- Integrante 4 -->
                          <div class="form-group row">

                            <div class="col-sm-4 d-flex align-items-center">
                              <p class="me-3">Dni: </p>
                              <input id="integrante-gs" placeholder="integrante 4"
                                v-model="solicitud.integrantesGruposSolidario.int4" type="text" class="form-control" />
                            </div>
                            <div class="col-sm-5 d-flex align-items-center">
                              <i class="me-4">{{ this.nomInt4 }}</i>
                              <i v-if="(this.solicitud.integrantesGruposSolidario.int4 === this.solicitud.coordinadorGrupoSolidario) && (this.nomInt4)"
                                class="fa-solid fa-star"></i>
                            </div>
                            <div v-if="(this.nomInt4)" class="col-sm-3 d-flex align-items-center">
                              <button class="btn btn-primary" type="button"
                                @click="seleccionarCoordinador(solicitud.integrantesGruposSolidario.int4, 1)">Seleccionar
                                Coordinador</button>
                            </div>

                            <div class="col-sm-4 d-flex align-items-center">
                              <p class="me-3">Monto:</p>

                              <input placeholder="Monto integrante 4"
                                v-model="solicitud.integrantesGruposSolidario.monto4" type="text"
                                class="form-control" />
                            </div>
                            <div class="col-sm-4 d-flex align-items-center">
                              <p class="me-3">Cuota: </p>
                              <input placeholder="Cuota integrante 4"
                                v-model="solicitud.integrantesGruposSolidario.cuota4" type="text"
                                class="form-control" />
                            </div>

                          </div>
                          <!-- Integrante 5 -->
                          <div class="form-group row">

                            <div class="col-sm-4 d-flex align-items-center">
                              <p class="me-3">Dni: </p>
                              <input id="integrante-gs" placeholder="integrante 5"
                                v-model="solicitud.integrantesGruposSolidario.int5" type="text" class="form-control" />
                            </div>
                            <div class="col-sm-5 d-flex align-items-center">
                              <i class="me-4">{{ this.nomInt5 }}</i>
                              <i v-if="(this.solicitud.integrantesGruposSolidario.int5 === this.solicitud.coordinadorGrupoSolidario) && (this.nomInt5)"
                                class="fa-solid fa-star"></i>
                            </div>
                            <div v-if="(this.nomInt5)" class="col-sm-3 d-flex align-items-center">
                              <button class="btn btn-primary" type="button"
                                @click="seleccionarCoordinador(solicitud.integrantesGruposSolidario.int5, 1)">Seleccionar
                                Coordinador</button>
                            </div>
                            <div class="col-sm-4 d-flex align-items-center">
                              <p class="me-3">Monto:</p>

                              <input placeholder="Monto integrante 5"
                                v-model="solicitud.integrantesGruposSolidario.monto5" type="text"
                                class="form-control" />
                            </div>
                            <div class="col-sm-4 d-flex align-items-center">
                              <p class="me-3">Cuota: </p>
                              <input placeholder="Cuota integrante 5"
                                v-model="solicitud.integrantesGruposSolidario.cuota5" type="text"
                                class="form-control" />
                            </div>

                          </div>
                        </div>


                      </div>
                    </div>
                  </div>


                  <!-- Garantías -->
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseLast" aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseLast">
                        <div class="col-6">Garantía</div>
                        <div id="validar-8" class="d-flex justify-content-end me-3" style="width: 100%"></div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseLast" class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingOne">
                      <div class="accordion-body">
                        {{ this.solicitudCompleta.producto }}
                        <div class=" mt-4">
                          <label for="garantia" class="mb-3">Selecciona una garantía:</label>
                          <select :required="(this.modoThanos===true)?false:true"  v-model="solicitud.garantia" :value="solicitud.garantia"
                            class="form-control mb-3" id="garantia" name="garantia">
                            <option value="Garantía Fiduciaria">Garantía Fiduciaria</option>
                            <option value="Aval Comerciante">Aval Comerciante </option>
                            <option value="Garantía Mobiliaria">Garantía Mobiliaria</option>
                            <option value="Garantía Prendaria">Garantía Prendaria </option>
                            <option value="Garantía Hipotecaria">Garantía Hipotecaria </option>
                            <option value="Grupo Solidario">Grupo Solidario </option>
                          </select>
                        </div>
                        {{ solicitud.garantia }}
                        <div id="info_garantia"></div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingSummaryTable">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseSummaryTable" aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseSummaryTable">
                        <div class="col-6">
                          Resumen del Crédito
                        </div>
                        <div id="validar-2" class="d-flex justify-content-end me-3" style="width: 100%">

                        </div>
                      </button>
                    </h2>
                    <div id="panelsStayOpen-collapseSummaryTable" class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingSummaryTable">
                      <div class="accordion-body">
                        <div class="container" id="content">
                          <hr>
                          <table class="datos-table">

                            <tr>
                              <td> <b>Nombre:</b> </td>
                              <td>{{ this.cliente?.nombres }} {{ this.cliente?.apellidos }}</td>
                              <td><b>ID Beneficiario:</b> </td>
                              <td>{{ this.cliente?.dni }}</td>
                            </tr>
                            <tr>
                              <td><b>Producto:</b> </td>
                              <td>{{ this.solicitud?.producto }}</td>
                              <td><b>Número de préstamo:</b> </td>
                              <td>AHDE-{{ this.solicitud?.id }}</td>
                            </tr>
                            <tr>
                              <td> <b> IFI: </b> </td>
                              <td>{{ this.cliente?.nombreBanco }}</td>
                              <td><b>Cuenta depósito:</b> </td>
                              <td>{{ this.cliente?.noCuentaBanco }}</td>
                            </tr>
                          </table>
                          <hr />
                          <table class="datos-prestamo-table custom-table">
                            <tr>
                              <td><b>Fecha Apertura:</b></td>
                              <!-- <td colspan="1">{{ new Date(this.solicitud?.fechaPago).toLocaleDateString('en-GB') }}</td> -->
                              <td colspan="1">{{ new Date(this.solicitud?.fechaPago).toLocaleDateString('en-GB', {
                                timeZone: 'UTC'
                              }) }}</td>

                              <td><b>Monto Aprobado:</b></td>
                              <td>{{formatoNumero(this.solicitud.monto)}} {{ this.moneda }}</td>

                              <td></td>
                            </tr>
                            <tr>
                              <td><b>Tasa Interes:</b></td>
                              <td>{{ this.solicitud?.interes }}%</td>
                              <td><b>Plazo (meses):</b></td>
                              <td>{{ this.solicitud?.plazoPago }}</td>
                              <td></td>
                            </tr>
                            <tr>
                              <td><b>Forma de pago:</b></td>
                              <td>{{ this.solicitud?.frecuenciaPago }}</td>
                              <td><b>Moneda:</b></td>
                              <td>Lempiras</td>
                              <td></td>
                            </tr>
                            <tr v-if="this.solicitud.producto === 'Credi Trabajo'">
                              <td><b> Total Interes: </b></td>
                              <td>{{ (this.solicitud?.monto * ((this.solicitud?.interes / 12) /
                                100) * this.solicitud.plazoPago).toLocaleString('en-US', minimumFractionDigits = 2,
                                  maximumFractionDigits = 2) }}</td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>

                            <tr>
                              <td colspan="1"><b>Tipo de cálculo:</b></td>
                              <td>Cuota Nivelada</td>
                              <td><b>Periodo Base:</b></td>
                              <td>360</td>
                              <td></td>
                            </tr>


                          </table>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>
              </form-tag>
              <!-- MODAL PARA CAMBIAR ESTADO DE LA SOLICITUD -->
              <div class="modal fade" id="estadoSolicitud" tabindex="-1" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">Estado de la Solicitud</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <EstadoSolicitud :idSolicitud="this.idSolicitud" />

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
    <!-- partial:../../partials/_footer.html -->
    <footer class="footer">
      <div class="d-sm-flex justify-content-center justify-content-sm-between">
        <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright ©
          AHDECO 2023</span>

      </div>
    </footer>
    <!-- partial -->
  </div>
</template>

<script>
import Security from "../security";
import FormTag from "@/components/forms/Form-Tag-Pagos.vue";
import { solicitudCompleta } from "../solicitud";
import router from "../../router/index.js";
import Swal from "sweetalert2";
import TextInputForm from '../forms/Text-Input-Forms.vue';
import EstadoSolicitud from "../forms/EstadoSolicitud.vue";
import direccionData from './direccionData.json';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { exportacionContratos } from './ExportacionContratos'
import { exportacionPagares } from './ExportacionPagare'
import { exportacionFianza } from './ExportacionFianza'
// import PlanPagos from '../plan-pagos.vue';


export default {
  name: "NuevaSolicitud",
  components: {
    "form-tag": FormTag,
    // "plan-pagos": PlanPagos,
    TextInputForm,
    EstadoSolicitud
  },
  data() {
    return {
      exportacionContratos,
      exportacionPagares,
      exportacionFianza,
      departamentos: [],
      departamentoSeleccionado: null,
      planPagoToPrint: [],
      comisionDesembolso: 2.50,
      montoComisionDesembolso: 0.0,
      totalComisionDesembolso: 0.0,
      comisionAsistenciaTecnica: 3.50,
      totalComisionAsistenciaTecnica: 0.0,
      tasaSeguridad: 0.40,
      totalTasaSeguridad: 0.0,
      montoNeto: 0,
      montoInteres: 0.0,
      idSolicitud: 0,
      validarAcordion: "",
      solicitudCompleta,
      fechaNacimiento: "",
      edadsoli: "",
      estadoCivil: "",
      final: false,
      noReferencias: 1,
      dni: "",

      cliente: {},
      gruposSolidariosArray: [],
      fechaVencimiento: '',
      solicitud: {
        ID: 0,
        clienteId: 0,
        producto: "",
        destinoCreditos: [],
        tiempoVivienda: "",
        materialVivienda: "",
        noPersonasVivivenda: "",
        noFamilias: "",
        noTrabajan: "",
        serviciosPublico: [],
        noDependientes: "",

        noBuscanEmpleo: "",
        planInversion: {
          req1: "",
          req2: "",
          req3: "",
          req4: "",
          req5: "",
          desc1: "",
          desc2: "",
          desc3: "",
          desc4: "",
          desc5: "",
          val1: 0,
          val2: 0,
          val3: 0,
          val4: 0,
          val5: 0,
        },
        filas: [],
        indexFilas: 0,
        referencias: {
          nom1: "",
          nom2: "",
          nom3: "",
          nom4: "",
          par1: "",
          par2: "",
          par3: "",
          par4: "",
          dir1: "",
          dir2: "",
          dir3: "",
          dir4: "",
          cel1: 0,
          cel2: 0,
          cel3: 0,
          cel4: 0,
        },
        sector: [],
        gruposEspeciales: [],
        idGrupoSolidario: 0,

        fechaPago: (new Date()).toISOString().slice(0, 10),
        monto: '0',
        montoDesembolsar: '0.0',
        frecuenciaPago: "",
        interes: 12,
        tipoPlazo: "mensual",
        plazoPago: '',
        garantia: '',
        estado: '',
        sucursal: "",

        nombreGrupoSolidario: '',
        integrantesGruposSolidario: {
          int1: "",
          monto1: "",
          cuota1: "",
          nombre1:"",
          rtn1:"",
          infoBeneficiario1:{},

          int2: "",
          monto2: "",
          cuota2: "",
          nombre2:"",
          rtn2:"",
          infoBeneficiario2:{},

          int3: "",
          monto3: "",
          cuota3: "",
          nombre3:"",
          rtn3:"",
          infoBeneficiario3:{},

          int4: "",
          monto4: "",
          cuota4: "",
          nombre4:"",
          rtn4:"",
          infoBeneficiario4:{},

          int5: "",
          monto5: "",
          cuota5: "",
          nombre5:"",
          rtn5:"",
          infoBeneficiario5:{},

        },
        coordinadorGrupoSolidario: '',
      },
      montoDesembolsar: "",
      moneda: 'lps',
      // comisionDesembolso: 0,
      // comisionAsistenciaTecnica: 0,
      // tasaSeguridad: 0,
      userRoles: [],
      Asesor: false,
      cuotaSolicitud: 0,

      // Grupo Solidario Nombres

      nomInt1: "",
      nomInt2: "",
      nomInt3: "",
      nomInt4: "",
      nomInt5: "",

      // Roles
      roles: ['Coordinacion', 'Admin', 'Finanzas', 'Asesor', 'Cordinador Sucursal', 'Consultor'],
      Finanzas: false,
      Admin: false,
      Coordinacion: false,
      coordinadorSucursal: false,
      modoThanos: false,
      Consultor: false,
    };
  },
  beforeMount() {

    

    this.idSolicitud = this.$route.params.solicitudId
    this.departamentos = direccionData[0].departamentos

    this.userRoles = Security.getUserRoles();
    // console.log(this.userRoles);
    this.roles.forEach(element => {

      if (this.userRoles[0]?.some(role => role.includes(element)) === true) {
        this[element] = true;
      } else if (this.userRoles[0]?.some(role => role.includes('Coordinador Sucursal')) === true) {
        this.coordinadorSucursal = true;
        // console.log('coordinador sucursal en true')
      } else if (this.userRoles[0]?.some(role => role.includes('Modo Thanos')) === true) {
        this.modoThanos = true;
        // console.log('Modo thanos en true')
      }
      /*       if (this.userRoles[0]?.some(role => role.includes('Coordinador Sucursal')) === true) {
            } */


    });

    // Obtener GrupoSolidario por edit is id > 0
    if (this.idSolicitud > 0) {
      //editing a solicitante
      fetch(
        process.env.VUE_APP_API_URL +
        "/admin/solicitud/" +
        this.$route.params.solicitudId,
        Security.requestOptions("")
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            this.$emit("error", data.message);
          } else {
            // this.Grupo = data.data;
            console.log("Dataa:", data.data[0].cliente);
            if (!this.creditrabajo2()) {

              let currentSol = data.data.find((sol) => sol.id == this.$route.params.solicitudId)
              this.solicitud = currentSol
              this.gruposSolidariosArray = data.data
              this.solicitud.fechaPago = currentSol.fechaPago
              this.solicitud.frecuenciaPago = currentSol.frecuenciaPago.toLowerCase()
              this.cliente = currentSol.cliente
              // solicitudCompleta.nombreGrupoSolidario = data.data.nombreGrupoSolidario
              // solicitudCompleta.integrantes = data.data.clientes
              // this.dni = data.data.dni;
              // this.cliente.estado = data.data.estado;
              this.solicitudCompleta.producto = this.solicitud.producto
              this.comisionAsistenciaTecnica = 3.50
              this.comisionDesembolso = 2.50
              this.tasaSeguridad = 0.40
              this.montoDesembolsar = this.solicitud.montoDesembolsar
              this.totalComisionDesembolso = this.solicitud.monto * (this.comisionDesembolso / 100)
              this.totalComisionAsistenciaTecnica = this.solicitud.monto * (this.comisionAsistenciaTecnica / 100)
              this.totalTasaSeguridad = this.solicitud.monto * (this.tasaSeguridad / 100)
              this.montoNeto = this.solicitud.monto - this.totalComisionDesembolso - this.totalComisionAsistenciaTecnica - this.totalTasaSeguridad
              // this.fechaPago = this.solicitud.fechaPago.length < 10 ? ((new Date()).toISOString().slice(0,10)) : this.solicitud.fechaPago
              this.fechaPago = (new Date()).toISOString().slice(0, 10)
              console.log(this.solicitud)


              this.ejecutarPlanPagos()
            } else {

              console.log("Data", data.data);

            }
          }
        });
    } else if (solicitudCompleta.producto == "") {
      router.push("/solicitud");
      location.reload();
      window.location.href = "/solicitud";
    }
    // if (this.validarMiPyme) {
    //   this.solicitudCompleta.integranteSolicitud+2
    // }
    if (this.creditrabajo2) {
      this.solicitud.frecuenciaPago = 'semanal'
    }
  },
  mounted() {
    Security.requireToken();
    this.ejecutarPlanPagos()
    // if (!this.final) {
    //   Security.secureReload();
    // }

    const valorInputs = document.querySelectorAll(".valor");
    const totalCell = document.getElementById("total");

    // Actualiza el total cuando se cambian los valores
    function actualizarTotal() {
      let total = 0;
      valorInputs.forEach((input) => {
        total += parseFloat(input.value) || 0;
      });
      totalCell.textContent = total;
    }

    // Escucha eventos de cambio en los inputs de valor
    valorInputs.forEach((input) => {
      input.addEventListener("input", actualizarTotal);
    });

    // Inicializa el total
    actualizarTotal();




    //Agregar Solicitante
    // if(this.solicitudCompleta.producto != 'MIPYME'){
    //   // this.cliente = solicitudCompleta.integranteSolicitud[solicitudCompleta.integranteSolicitud]

    this.cliente =
      this.solicitudCompleta.integrantes[
      this.solicitudCompleta.integranteSolicitud
      ];
  },
  methods: {
    dateFormat(param) {
      // const date = new Date(param)

      // const dia = (param)?.slice(8, 10)
      // const mes = date.toLocaleDateString('es-ES', { month: 'long' })
      // const anio = date.getFullYear()
      // const dateFormated = `${dia}/${mes.slice(0, 3)}/${anio}`
      // return dateFormated
      // Fecha en formato ISO 8601
      // Fecha en formato ISO 8601
      var fechaISO = param;


      // Crear un objeto Date con la fecha proporcionada
      var fecha = new Date(fechaISO);

      // Obtener el día del mes (agregando un cero inicial si es necesario)
      var dia = ('0' + fecha.getUTCDate()).slice(-2);

      // Obtener el mes (agregando un cero inicial si es necesario)
      var mes = ('0' + (fecha.getUTCMonth() + 1)).slice(-2);

      // Obtener el año
      var anio = fecha.getUTCFullYear();

      // Formatear la fecha según tus necesidades
      var fechaFormateada = dia + '/' + mes + '/' + anio;

      console.log(fechaFormateada); // Salida: 01/12/2023

      return fechaFormateada;

    },
    obtenerCoordinador() {
      if (this.solicitud.coordinadorGrupoSolidario)

        switch (this.solicitud.coordinadorGrupoSolidario) {
          case this.solicitud.integrantesGruposSolidario.int1:
            return this.nomInt1
          case this.solicitud.integrantesGruposSolidario.int2:
            return this.nomInt2
          case this.solicitud.integrantesGruposSolidario.int3:
            return this.nomInt3
          case this.solicitud.integrantesGruposSolidario.int4:
            return this.nomInt4
          case this.solicitud.integrantesGruposSolidario.int5:
            return this.nomInt5
          default:
            return ""


        }
    },
    formatoNumero(numero) {
      return new Intl.NumberFormat("en-US").format(numero)
    },
    seleccionarCoordinador(dni, integrante) {

      console.log(dni, integrante);
      this.solicitud.coordinadorGrupoSolidario = dni
    },
    creditrabajo2() {
      return this.solicitudCompleta.producto === 'Credi Trabajo 2'

    }
    ,
    onBlur() {
      //Funcion para calcular el monto del credito
      if (this.montoDesembolsar > 0) {
        if (this.solicitud.plazoPago > 0 && this.solicitud.plazoPago < 7) {

          this.totalComisionDesembolso = (4.5 / 100) * this.montoDesembolsar
          this.totalComisionAsistenciaTecnica = (4.0 / 100) * this.montoDesembolsar
          this.totalTasaSeguridad = (0.4 / 100) * this.montoDesembolsar
          this.montoInteres = (((this.montoDesembolsar * (5 / 100)) * this.solicitud.plazoPago) - (this.totalComisionAsistenciaTecnica + this.totalComisionDesembolso))
          // this.solicitud.monto = this.montoDesembolsar + this.montoInteres
          this.totalComisionDesembolso = parseInt(this.totalComisionDesembolso, 10).toFixed(2)
          this.totalComisionAsistenciaTecnica = parseInt(this.totalComisionAsistenciaTecnica, 10).toFixed(2)
          // Convertir los valores a enteros antes de sumarlos
          const montoDesembolsar = parseInt(this.montoDesembolsar, 10);
          const montoInteres = parseInt(this.montoInteres, 10);
          const totalComisionDesembolso = parseInt(this.totalComisionDesembolso, 10);
          const totalTasaSeguridad = parseInt(this.totalTasaSeguridad, 10);
          const totalComisionAsistenciaTecnica = parseInt(this.totalComisionAsistenciaTecnica, 10);

          // Asignar la suma a solicitud.monto
          this.solicitud.monto = (montoDesembolsar + montoInteres + totalComisionDesembolso + totalTasaSeguridad + totalComisionAsistenciaTecnica).toFixed(2);

          console.log("El campo de interés perdió el foco. Valor actual: " + this.totalComisionDesembolso);

        }
      }
      // Lógica adicional que deseas ejecutar cuando se sale del input
    },
    quitarFila() {
      // Agregar una nueva fila al arreglo de filas
      if (this.solicitud.filas.length > 0) {
        // Eliminar la última fila del arreglo de filas
        this.solicitud.filas.pop();
      }
    },
    agregarFila() {
      // Agregar una nueva fila al arreglo de filas
      this.solicitud.filas.push({ requerimiento: '', descripcion: '', valor: 0 });
    },
    exportarSolicitudPDF() {
      const doc = new jsPDF()
      const text = 'SOLICITUD DE CRÉDITO SUBSIGUIENTE';
      // const textWidth = doc.getTextWidth(text);
      // const centerX = (doc.internal.pageSize.width - textWidth) / 2;
      const y = 20;
      var tableHeight

      // Calcula las dimensiones del documento
      const docWidth = doc.internal.pageSize.getWidth();
      // Agrega una imagen al documento
      const img = new Image();
      img.src = require('@/assets/header.png'); // Reemplaza 'mi_imagen.jpg' con el nombre de tu imagen
      // Calcula las dimensiones de la imagen para que ocupe todo el ancho de la página
      const imgWidth = docWidth;
      // const imgHeight = (img.height * imgWidth) / img.width;
      // doc.addImage(img, 'PNG', 10, 10, 300, 200); // Ajusta las coordenadas y el tamaño según tu necesidad
      doc.addImage(img, 'PNG', 0, 0, imgWidth, 30);
      doc.setFontSize(12)
      doc.setTextColor(255, 255, 255);
      doc.text(text, 110, (y - 4));

      doc.setFontSize(12)
      doc.setTextColor(0, 0, 0);

      const product = 'Producto: ' + ((this.solicitud.producto == 'Credi Trabajo 2' ? 'Creditrabajo' : this.solicitud.producto))
      const date = 'Fecha ' + new Date().toLocaleDateString('en-GB')

      // doc.text(product, 10, 20)
      doc.setFontSize(10)
      doc.text(product, 10, 35, { align: 'left' });

      doc.text(date, doc.internal.pageSize.width - 10, 35, { align: 'right' });
      doc.setFontSize(12)
      doc.autoTable({
        body: [
          [{ content: 'DATOS PERSONALES DEL SOLICITANTE', colSpan: 6, cellStyles: { lineWidth: [0, 0, 0, 0] }, styles: { fontStyle: 'bold' } }, { content: `Nombre del Grupo: ${this.solicitud?.nombreGrupoSolidario}`, colSpan: 6 }],
          [{ content: `Nombre Completo: ${this.cliente?.nombres ? this.cliente?.nombres : '-'} ${this.cliente?.apellidos ? this.cliente?.apellidos : ''}`, colSpan: 12, alignment: 'left', fontStyle: 'bold', halign: 'left' }],
          [{ content: `Número de Identidad: ${this.cliente?.dni ? this.cliente?.dni : ''}`, colSpan: 6 }, { content: `Lugar de Nacimiento: ${this.cliente?.lugarNacimiento ? this.cliente?.lugarNacimiento : ''}`, colSpan: 3 }, { content: `Sexo: ${this.cliente?.sexo ? this.cliente?.sexo : ''}`, colSpan: 3 }],

          [{ content: `Estado Civil: ${this.cliente?.estadoCivil ? this.cliente?.estadoCivil : '-'}`, colSpan: 5 }, { content: `Fecha de Nacimiento: ${this.cliente?.fechaNacimiento ? this.cliente?.fechaNacimiento : '-'}`, colSpan: 4 }, { content: `Edad: ${this.cliente.edad ? this.cliente.edad : '-'}`, colSpan: 3 }],
          [{ content: `Correo Electronico: ${this.cliente?.email ? this.cliente?.email : '-'}`, colSpan: 6 }, { content: `Telefono: ${this.cliente.telefono ? this.cliente.telefono : ''}`, colSpan: 3 }, { content: `Celular: ${this.cliente?.celular ? this.cliente?.celular : "-"}`, colSpan: 3 }],
          [{ content: `Nombre del Banco: ${this.cliente?.nombreBanco ? this.cliente?.nombreBanco : '-'}`, colSpan: 6 }, { content: `Número de cuenta del Banco: ${this.cliente?.noCuentaBanco ? this.cliente?.noCuentaBanco : '-'}`, colSpan: 6 }]
        ],
        startY: 40,
        theme: 'grid',
        styles: {
          halign: 'left',
        },
        border: [{ top: 1, right: 1, bottom: 1, left: 1 }],
        didDrawPage: function (data) {
          // Guardar la altura de la tabla
          tableHeight = data.cursor.y;
        }
      })


      tableHeight += 10
      const text2 = 'DATOS DOMICILIARES DEL SOLICITANTE';
      doc.text(text2, 15, tableHeight);
      tableHeight += 5
      doc.autoTable({
        body: [
          [
            { content: `Depto.: ${this.cliente?.departamento ? this.cliente.departamento : '-'}`, colSpan: 4 },
            { content: `Municipio: ${this.cliente?.municipio ? this.cliente.municipio : '-'}`, colSpan: 4 },
            { content: `Col/ Bar/ Ald: ${this.cliente?.colonia ? this.cliente.colonia : '-'}`, colSpan: 4 },
            { content: `Calle: ${this.cliente?.calle ? this.cliente.calle : '-'}`, colSpan: 2 },
            { content: `Avenida: ${this.cliente?.avenida ? this.cliente.avenida : '-'}`, colSpan: 2 },
            { content: `Bloque: ${this.cliente?.bloque ? this.cliente.bloque : '-'}`, colSpan: 2 },
            { content: `Casa No. ${this.cliente?.noCasa ? this.cliente.noCasa : '-'}`, colSpan: 2 },
          ],
          [
            { content: `Punto de Referencia: ${this.cliente.puntoReferencia ? this.cliente.puntoReferencia : '-'}`, colSpan: 12 },
            { content: `Tipo de vivienda: ${this.cliente.tipoVivienda ? this.cliente.tipoVivienda : '-'}`, colSpan: 4 },
            { content: `Cuota de Vivienda: ${this.cliente?.cuotaVivienda ? this.cliente.cuotaVivienda : '-'}`, colSpan: 4 },
          ],
          [
            { content: `Si Alquila (Nombre y Telefono del propietario): ${this.cliente?.propietarioAlquila ? this.cliente?.propietarioAlquila : '-'}`, colSpan: 20 },
          ],
        ],
        startY: tableHeight,
        theme: 'grid',
        styles: {
          halign: 'left',
        },
        didDrawPage: function (data) {
          // Guardar la altura de la tabla
          tableHeight = data.cursor.y;
        }
      });

      tableHeight += 10
      doc.text('DATOS DEL NEGOCIO DEL SOLICITANTE', 15, tableHeight)
      tableHeight += 5
      doc.autoTable({
        body: [
          [
            { content: `Destino: ${this.cliente?.destino ? this.cliente.destino : '-'}`, colSpan: 4 }, { content: `Tipo del Cliente: ${this.cliente?.tipoCliente ? this.cliente.tipoCliente : '-'}`, colSpan: 4 }, { content: `Actividad Económica: ${this.cliente?.actividadEconomica ? this.cliente.actividadEconomica : '-'}`, colSpan: 4 }, { content: `Tiempo de tener el negocio: ${this.cliente?.tiempoNegocio ? this.cliente.tiempoNegocio : '-'}`, colSpan: 4 },
          ],
          [
            { content: `Tipo de Local: ${this.cliente?.tipoLocal ? this.cliente.tipoLocal : '-'}`, colSpan: 4 }, { content: `Cuota de Alquiler: ${this.cliente?.cuotaAlquilerNegocio ? this.cliente?.cuotaAlquilerNegocio : ''}`, colSpan: 4 }, { content: `Ingreso Bruto del Negocio: ${this.cliente?.ingresoBrutoNegocio ? this.cliente?.ingresoBrutoNegocio : '-'}`, colSpan: 4 }, { content: `Teléfono: ${this.cliente?.telefono}`, colSpan: 4 },
          ],
          [
            { content: `Col/ Bar/ Ald: ${this.cliente.coloniaNegocio ? this.cliente.coloniaNegocio : '-'}`, colSpan: 3 },
            { content: `Zona / Sector: ${this.cliente.sectorNegocio ? this.cliente.sectorNegocio : '-'}`, colSpan: 3 },
            { content: `Calle: ${this.cliente.calleNegocio ? this.cliente.calleNegocio : '-'}`, colSpan: 3 },
            { content: `Avenida: ${this.cliente.avenidaNegocio ? this.cliente.avenidaNegocio : '-'}`, colSpan: 3 },
            { content: `Bloque: ${this.cliente.bloqueNegocio ? this.cliente.bloqueNegocio : '-'}`, colSpan: 2 },
            { content: `Casa No. ${this.cliente.noCasaNegocio ? this.cliente.noCasaNegocio : '-'}`, colSpan: 2 },
          ],
          [
            { content: `Punto de Referencia: ${this.cliente.puntoReferenciaNegocio}`, colSpan: 16 },
          ],
        ],
        startY: tableHeight,
        theme: 'grid',
        styles: {
          halign: 'left',
        },
        border: [{ top: 1, right: 1, bottom: 1, left: 1 }],
        didDrawPage: function (data) {
          // Guardar la altura de la tabla
          tableHeight = data.cursor.y;
        }
      })


      if (this.cliente.nombresConyugue != undefined) {
        tableHeight += 10
        doc.text('DATOS CONYUGUE', 15, tableHeight)
        tableHeight += 5
        doc.autoTable({
          body: [
            [
              { content: `Nombre Completo: ${this.cliente.nombresConyugue ? this.cliente.nombresConyugue : ''} ${this.cliente.apellidosConyugue ? this.cliente.apellidosConyugue : ''}`, colSpan: 5 },
              { content: `Número de Identidad: ${this.cliente.dniConyugue ? this.cliente.dniConyugue : '-'} `, colSpan: 5 },
            ],
            [
              { content: `Correo ELectrónico: ${this.cliente.emailConyugue ? this.cliente.emailConyugue : '-'}`, colSpan: 5 },
              { content: `Celular: ${this.cliente.celularConyugue ? this.cliente.celularConyugue : '-'}`, colSpan: 5 },
            ],
          ],
          startY: tableHeight,
          theme: 'grid',
          styles: {
            halign: 'left',
          },
          didDrawPage: function (data) {
            // Guardar la altura de la tabla
            tableHeight = data.cursor.y;
          }

        })
        doc.addPage()
        tableHeight = 20

      } else {

        tableHeight += 10
      }




      doc.text('REFERENCIAS', 15, tableHeight)
      tableHeight += 5

      doc.autoTable({
        head: [['Nombre', 'Parentesco', 'Dirección', 'Celular']],
        body: [
          [
            { content: this.solicitud.referencias.nom1 },
            { content: this.solicitud.referencias.par1 },
            { content: this.solicitud.referencias.dir1 },
            { content: this.solicitud.referencias.cel1 === 0 ? '' : this.solicitud.referencias.cel1 },
          ],
          [
            { content: this.solicitud.referencias.nom2 },
            { content: this.solicitud.referencias.par2 },
            { content: this.solicitud.referencias.dir2 },
            { content: this.solicitud.referencias.cel2 === 0 ? '' : this.solicitud.referencias.cel2 },
          ],
          [
            { content: this.solicitud.referencias.nom3 },
            { content: this.solicitud.referencias.par3 },
            { content: this.solicitud.referencias.dir3 },
            { content: this.solicitud.referencias.cel3 === 0 ? '' : this.solicitud.referencias.cel3 },
          ],
          [
            { content: this.solicitud.referencias.nom4 },
            { content: this.solicitud.referencias.par4 },
            { content: this.solicitud.referencias.dir4 },
            { content: this.solicitud.referencias.cel4 === 0 ? '' : this.solicitud.referencias.cel4 },
          ],
        ],
        startY: tableHeight,
        headStyles: { fillColor: [41, 128, 185], halign: 'center' },
        theme: 'grid',
        styles: {
          halign: 'left',
        },
        border: [{ top: 1, right: 1, bottom: 1, left: 1 }],
        didDrawPage: function (data) {
          // Guardar la altura de la tabla
          tableHeight = data.cursor.y;
        }
      })

      if ((this.cliente.nombresConyugue == undefined)) {
        doc.addPage()
        tableHeight = 30
      } else {
        tableHeight += 10
      }

      doc.text('DESTINO DEL CRÉDITO:', 15, tableHeight)

      tableHeight += 5

      doc.autoTable({
        body: [
          [{ content: ` Emprender un Negocio  [ ${this.solicitud.destinoCreditos.includes('Emprender un Negocio') ? ' X ' : ''} ]   Comprar maquinaria y equipo  [ ${this.solicitud.destinoCreditos.includes('Comprar maquinaria y equipo') ? ' X ' : ''} ]   Reabastecer el negocio  [ ${this.solicitud.destinoCreditos.includes('Reabastecer el negocio') ? ' X ' : ''} ]   Otras  [ ${this.solicitud.destinoCreditos.includes('Otras') ? ' X ' : ''} ]` }],
        ],
        startY: tableHeight,
        theme: 'plain',
        didDrawPage: function (data) {
          // Guardar la altura de la tabla
          tableHeight = data.cursor.y;
        }
      })
      tableHeight += 5
      doc.setFontSize(10)
      doc.text('1. Certifico que la información arriba descrita es verídica y autorizo para que sea confirmada si así fuera el caso \ncualquier dato falso sea motivo para la no aprobación del préstamo.', 15, tableHeight)
      tableHeight += 20
      doc.setFontSize(12)
      doc.text('PLAN DE INVERSION:', 15, tableHeight)
      doc.setFontSize(10)
      tableHeight += 8
      doc.text('Inventario de producto, materia prima, mano de obra, mobiliario, equipo de producción y equipo de transporte \nque requiere:', 15, tableHeight)
      tableHeight += 8
      doc.autoTable({
        head: [['Requerimiento', 'Descripción', 'Valor (Monto L.)']],
        body: [
          [
            { content: this.solicitud.planInversion.req1 },
            { content: this.solicitud.planInversion.desc1 },
            { content: this.solicitud.planInversion.val1 === 0 ? '' : this.solicitud.planInversion.val1 },
          ],
          [
            { content: this.solicitud.planInversion.req2 },
            { content: this.solicitud.planInversion.desc2 },
            { content: this.solicitud.planInversion.val2 === 0 ? '' : this.solicitud.planInversion.val2 },
          ],
          [
            { content: this.solicitud.planInversion.req3 },
            { content: this.solicitud.planInversion.desc3 },
            { content: this.solicitud.planInversion.val3 === 0 ? '' : this.solicitud.planInversion.val3 },
          ],
          [
            { content: this.solicitud.planInversion.req4 },
            { content: this.solicitud.planInversion.desc4 },
            { content: this.solicitud.planInversion.val4 === 0 ? '' : this.solicitud.planInversion.val4 },
          ],
          [
            { content: this.solicitud.planInversion.req5 },
            { content: this.solicitud.planInversion.desc5 },
            { content: this.solicitud.planInversion.val5 === 0 ? '' : this.solicitud.planInversion.val5 },
          ],
        ],
        startY: tableHeight,
        headStyles: { fillColor: [41, 128, 185], halign: 'center' },
        theme: 'grid',
        styles: {
          halign: 'left',
        },
        border: [{ top: 1, right: 1, bottom: 1, left: 1 }],
      })

      doc.addPage()
      let text3 = 'Sectores Y Grupos Especiales';
      const textWidth = doc.getTextWidth(text3);
      const centerX = (doc.internal.pageSize.width - textWidth) / 2;
      let y2 = 25;

      // doc.addImage(img, 'PNG', 10, 10, 300, 200); // Ajusta las coordenadas y el tamaño según tu necesidad
      doc.setFontSize(12)

      doc.text(text3, centerX, (y2));
      doc.setFontSize(10)
      doc.autoTable({
        head: [[{ content: 'Sector', colSpan: 8, styles: { fontStyle: 'bold' } }]],
        body: [
          [{ content: 'Adulto Mayor', colSpan: 6 }, { content: this.solicitud.sector.includes('Adulto Mayor') ? 'X' : '', colSpan: 2, styles: { halign: 'center' } }],
          [{ content: 'Afrohondureños', colSpan: 6 }, { content: this.solicitud.sector.includes('Afrohondureños') ? 'X' : '', colSpan: 2, styles: { halign: 'center' } }],
          [{ content: 'Discapacitados', colSpan: 6 }, { content: this.solicitud.sector.includes('Discapacitados') ? 'X' : '', colSpan: 2, styles: { halign: 'center' } }],
          [{ content: 'Extema Pobreza', colSpan: 6 }, { content: this.solicitud.sector.includes('Extema Pobreza') ? 'X' : '', colSpan: 2, styles: { halign: 'center' } }],
          [{ content: 'Jóvenes', colSpan: 6 }, { content: this.solicitud.sector.includes('Jóvenes') ? 'X' : '', colSpan: 2, styles: { halign: 'center' } }],
          [{ content: 'Migrante Retornado', colSpan: 6 }, { content: this.solicitud.sector.includes('Migrante Retornado') ? 'X' : '', colSpan: 2, styles: { halign: 'center' } }],
          [{ content: 'Mujer Campesina', colSpan: 6 }, { content: this.solicitud.sector.includes('Mujer Campesina') ? 'X' : '', colSpan: 2, styles: { halign: 'center' } }],
          [{ content: 'Mujeres en Situación de Vulnerabilidad', colSpan: 6 }, { content: this.solicitud.sector.includes('Mujeres en Situación de Vulnerabilidad') ? 'X' : '', colSpan: 2, styles: { halign: 'center' } }],
          [{ content: 'Pobreza', colSpan: 6 }, { content: this.solicitud.sector.includes('Pobreza') ? 'X' : '', colSpan: 2, styles: { halign: 'center' } }],
        ],
        startY: 35,
        headStyles: { fillColor: [41, 128, 185], halign: 'center' },
        theme: 'grid',
        styles: {
          halign: 'left',
        },

      })

      doc.autoTable({
        head: [[{ content: 'Grupos Especiales', colSpan: 16, styles: { fontStyle: 'bold' } }]],
        body: [
          [
            { content: 'Pueblos Indígenas', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Pueblos Indígenas') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
            { content: 'DINAFROH', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('DINAFROH') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },

          ],
          [
            { content: 'Credi-Feria', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Credi-Feria') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
            { content: 'DINIS', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('DINIS') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },

          ],
          [
            { content: 'Crédito del SSE', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Crédito del SSE') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
            { content: 'Progresa', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Progresa') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },

          ],
          [
            { content: 'Reservistas', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Reservistas') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
            { content: 'Tu Banca Joven', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Tu Banca Joven') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
          ],
          [
            { content: 'Fundeimh', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Fundeimh') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
            { content: 'Caja Rural', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Caja Rural') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
          ],
          [
            { content: 'Projoven', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Projoven') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
            { content: 'Bici Solidaria', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Bici Solidaria') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
          ],
          [
            { content: 'Anmpih', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Anmpih') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
            { content: 'CICESCT', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('CICESCT') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
          ],
          [
            { content: 'Ayuda Solidaria', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Ayuda Solidaria') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
            { content: 'Credi-Taxi', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Credi-Taxi') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
          ],
          [
            { content: 'Credi-Salón', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Credi-Salón') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
            { content: 'Credi-Babería', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Credi-Babería') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
          ],
          [
            { content: 'Credi-Pulpería', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Credi-Pulpería') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
            { content: 'Senaeh', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Senaeh') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
          ],
          [
            { content: 'Ciudad Mujer', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Ciudad Mujer') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
            { content: 'Asonapvsidah', colSpan: 4 }, { content: this.solicitud.gruposEspeciales.includes('Asonapvsidah') ? 'X' : '', colSpan: 4, styles: { halign: 'center' } },
          ]
          // Add more rows here...
        ],
        startY: 125,
        headStyles: { fillColor: [41, 128, 185], halign: 'center' },
        theme: 'grid',

        columnStyle: {
          0: { halign: 'left' },
          4: { halifn: 'center' },
          8: { halign: 'left' },
          12: { halifn: 'center' },
        }
      })

      doc.addPage()

      doc.text(`NOSOTROS, los bajo firmantes constituidos en el Grupo Solidario:   ${this.solicitud.nombreGrupoSolidario}`, 15, 35)
      doc.text('_____________________________________', 122, 35);
      doc.text(`Hemos designado a: ${this.obtenerCoordinador()}`, 15, 45)
      doc.text('_____________________________________', 47, 45);
      doc.text('como cordinador del Grupo Solidario, asímismo, garantizamos solidariamente los compromisos atraidos.', 15, 55)

      this.gruposSolidariosArray = [
        {
          nombre: this.nomInt1,
          monto: (this.nomInt1 ? (this.formatoNumero(this.solicitud.integrantesGruposSolidario.monto1) + " lps") : "0 lps"),
          cuota: this.solicitud.integrantesGruposSolidario.cuota1  + " lps",
        },
        {
          nombre: this.nomInt2,
          monto: (this.nomInt2 ? (this.formatoNumero(this.solicitud.integrantesGruposSolidario.monto2) + " lps") : "0 lps"),
          cuota: this.solicitud.integrantesGruposSolidario.cuota2  + " lps",
        },
        {
          nombre: this.nomInt3,
          monto: (this.nomInt3 ? (this.formatoNumero(this.solicitud.integrantesGruposSolidario.monto3) + " lps") : "0 lps"),
          cuota: this.solicitud.integrantesGruposSolidario.cuota3  + " lps",
        },
        {
          nombre: this.nomInt4,
          monto: (this.nomInt4 ? (this.formatoNumero(this.solicitud.integrantesGruposSolidario.monto4) + " lps") : "0 lps"),
          cuota: this.solicitud.integrantesGruposSolidario.cuota4  + " lps",
        }, 
        {
          nombre: this.nomInt5,
          monto: (this.nomInt5 ? (this.formatoNumero(this.solicitud.integrantesGruposSolidario.monto5) + " lps") : "0 lps"),
          cuota: this.solicitud.integrantesGruposSolidario.cuota5  + " lps",
        }
      ];

      doc.autoTable({
        head: [['No.', 'Nombre Completo', 'Monto', 'Valor Cuota', 'Firma']],
        body:
          this.gruposSolidariosArray.map((integrante, index) => {
            return ([{ content: `${index + 1}`, colSpan: 1 },
            { content: integrante.nombre, colSpan: 1 },
            { content: integrante.monto, colSpan: 1 },
            { content: integrante.cuota, colSpan: 1 },
            { content: '', colSpan: 1 }]
            )
          })
        ,
        startY: 60,
        headStyles: { fillColor: [41, 128, 185], halign: 'center' },
        theme: 'grid',
        styles: {
          halign: 'center',
        },
      })

      doc.setFontSize(12)      
      doc.setFont('helvetica', 'bold');

      doc.text('LÍNEA BASE: EVALUACIÓN DE CALIDAD Y CONDICIONES DE VIDA', 15, 115)
      doc.setFont('helvetica', 'normal');

      doc.setFontSize(11)
      doc.text('Información sobre la vivienda.', 15, 123)

      

      doc.autoTable({
        body: [
          [
            { content: `Material que predomina en vivienda: ${this.solicitud.materialVivienda}`, colSpan: 6 },
            { content: `Tiempo de vivir en la vivienda: ${this.solicitud.tiempoVivienda}`, colSpan: 6 },
          ],
          // [
          //   {
          //     content: `Marque con una x los servicios públicos que tiene en su vivienda: 
          //     \n\n energia electrica ( ${this.solicitud.serviciosPublico.includes('energia electrica') ? 'X' : ' '} )   red de agua potable ( ${this.solicitud.serviciosPublico.includes('red de agua potable') ? 'X' : ' '} )   red de aguas negras ( ${this.solicitud.serviciosPublico.includes('red de aguas negras') ? 'X' : ' '} )   pozo septico ( ${this.solicitud.serviciosPublico.includes('pozo septico') ? 'X' : ' '} )   telefono fijo ( ${this.solicitud.serviciosPublico.includes('telefono fijo') ? 'X' : ' '} )
          //     `, colSpan: 12
          //   },
          // ],
          // [
          //   { content: `No. de personas que viven en la vivienda: ${this.solicitud.noPersonasVivivenda} \n`, colSpan: 6 },
          //   { content: `No. de familias que viven en la vivienda: ${this.solicitud.noFamilias}\n`, colSpan: 6 },
          // ],
          // [
          //   { content: `No. de personas que trabajan en la vivienda: ${this.solicitud.noTrabajan} \n`, colSpan: 6 },
          //   { content: `No. de dependientes económicos: ${this.solicitud.noDependientes} \n`, colSpan: 6 },
          // ],
          // [
          //   { content: `No. de personas que buscan empleo: ${this.solicitud.noBuscanEmpleo} \n`, colSpan: 12 },
          // ],
        ],
        startY: 126,
        theme: 'plain',
        styles: {
          halign: 'left',
        },
        didDrawPage: function (data) {
          // Guardar la altura de la tabla
          tableHeight = data.cursor.y;
        }
      })
      tableHeight+=8
      doc.setFont('helvetica', 'bold');

      doc.text('Marque con una x los servicios públicos que tiene en su vivienda: ', 15, tableHeight)
      doc.setFont('helvetica', 'normal');

      tableHeight+=6
      doc.setFontSize(10)


      doc.text(`Energia electrica ( ${this.solicitud.serviciosPublico.includes('energia electrica') ? 'X' : ' '} )   Red de agua potable ( ${this.solicitud.serviciosPublico.includes('red de agua potable') ? 'X' : ' '} )   Red de aguas negras ( ${this.solicitud.serviciosPublico.includes('red de aguas negras') ? 'X' : ' '} )   Pozo septico ( ${this.solicitud.serviciosPublico.includes('pozo septico') ? 'X' : ' '} )   telefono fijo ( ${this.solicitud.serviciosPublico.includes('telefono fijo') ? 'X' : ' '} )`, 15, tableHeight)
      

      tableHeight+=8

      doc.text(`No. de personas que viven en la vivienda: ${this.solicitud.noPersonasVivivenda}`, 15, tableHeight)
      doc.text(`No. de familias que viven en la vivienda: ${this.solicitud.noFamilias}`, 110, tableHeight)
      tableHeight+=8
      doc.text(`No. de personas que trabajan en la vivienda: ${this.solicitud.noTrabajan}`, 15, tableHeight)
      doc.text(`No. de dependientes económicos: ${this.solicitud.noDependientes} `, 110, tableHeight)
      tableHeight+=8
      doc.text(`No. de personas que buscan empleo: ${this.solicitud.noBuscanEmpleo} `, 15, tableHeight)




      doc.text('_____________________________________', 65, 195);
      doc.autoTable({
        body: [
          [
            { content: '(f) Solicitante (Firma o Huella)', colSpan: 6 },
          ],
          [
            { content: 'Nosotros los abajo firmantes damos fé de haber validado en campo la información brindada por el solicitante, el cual es verídica.', colSpan: 6 },
          ]
        ],
        startY: 197,
        theme: 'plain',
        styles: {
          halign: 'center',
        },
      })

      doc.text('_________________________________', 25, 230);
      doc.text('_________________________________', 115, 230);
      doc.autoTable({
        body: [
          [
            { content: 'Oficial de Microcrédito', colSpan: 6 },
            { content: 'Jefe Departamental', colSpan: 6 },
          ]
        ],
        startY: 232,
        theme: 'plain',
        styles: {
          halign: 'center',
        },
      })


      doc.text('__________________', 27, 262);
      doc.text('____________', 40, 270);
      doc.text('____________', 101, 270);
      doc.setFont('helvetica', 'bold');

      doc.text('CONDICIONES DEL CRÉDITO', 15, 255)
      doc.setFont('helvetica', 'normal');

      doc.autoTable({
        body: [
          [
            { content: `Monto:           ${this.solicitud.monto}`, colSpan: 6 },
          ],
          [
            { content: `Plazo de Pago:            ${this.solicitud.plazoPago}              Frecuencia de Pago:     ${this.solicitud.frecuenciaPago}`, colSpan: 6 }
          ]
        ],
        startY: 257,
        theme: 'plain',
        styles: {
          halign: 'left',
        },
      })








      doc.save('Solicitud.pdf')

    }
    ,

    handleImprimir() {
      const doc = new jsPDF()

      // const text = 'PLAN DE PAGO';
      // const textWidth = doc.getTextWidth(text);

      // // Calcular la posición para centrar el texto
      // const centerX = (doc.internal.pageSize.width - textWidth) / 2;
      // const y = 10;

      // // Colocar el texto centrado
      // doc.text(text, centerX, y);

      // doc.setFont('courier')
      const text = 'PLAN DE PAGO';
      // const textWidth = doc.getTextWidth(text);
      // const centerX = (doc.internal.pageSize.width - textWidth) / 2;
      const y = 20;
      var tableHeight

      // Calcula las dimensiones del documento
      const docWidth = doc.internal.pageSize.getWidth();
      // Agrega una imagen al documento
      const img = new Image();
      img.src = require('@/assets/header.png'); // Reemplaza 'mi_imagen.jpg' con el nombre de tu imagen
      // Calcula las dimensiones de la imagen para que ocupe todo el ancho de la página
      const imgWidth = docWidth;
      // const imgHeight = (img.height * imgWidth) / img.width;
      // doc.addImage(img, 'PNG', 10, 10, 300, 200); // Ajusta las coordenadas y el tamaño según tu necesidad
      doc.addImage(img, 'PNG', 0, 0, imgWidth, 30);
      doc.setFontSize(12)
      doc.setTextColor(255, 255, 255);
      doc.text(text, 120, (y - 4));

      doc.setFontSize(12)
      doc.setTextColor(0, 0, 0);

      const tableData = [];
      const tableInfoPrestamo = [];
      document.querySelectorAll('.datos-table tr').forEach((row) => {
        const rowData = [];
        // Iterar sobre las celdas de cada fila
        row.querySelectorAll('td').forEach((cell) => {
          rowData.push(cell.innerText);
        });

        tableData.push(rowData);
      });

      document.querySelectorAll('.datos-prestamo-table tr').forEach((row) => {
        const rowData = [];
        // Iterar sobre las celdas de cada fila
        row.querySelectorAll('td').forEach((cell) => {
          rowData.push(cell.innerText);
        });

        tableInfoPrestamo.push(rowData);
      });
      this.ejecutarPlanPagos()

      // Crear la tabla en el PDF
      doc.autoTable({
        body: tableData,
        theme: 'striped', // Puedes cambiar el tema según tus preferencias
        startY: 30,
        styles: {
          halign: 'center',
        },
      });
      doc.autoTable({
        body: tableInfoPrestamo,
        theme: 'striped', // Puedes cambiar el tema según tus preferencias
        styles: {
          halign: 'left',
        },
      });
      doc.autoTable({
        head: [['No. Cuota', 'Fecha de Pago', 'Cuota Total', 'Interes', 'Capital', 'Saldo']],
        headStyles: { fillColor: [41, 128, 185] },
        body: this.planPagoToPrint.map(plan => [plan.noCuota, plan.fechaPago, plan.cuotaTotal, plan.interes, plan.capital, plan.saldo]),
        theme: 'grid',
        styles: {
          halign: 'center',
        },
        didDrawPage: function (data) {
          // Guardar la altura de la tabla
          tableHeight = data.cursor.y;
        }
      })
      tableHeight += 20
      // //        // Agregar texto al final de la página
      // let cuentaText = `Cuenta No. 15310167484 de BANRURAL`; // Tu texto aquí
      // const textWidth2 = doc.getTextWidth(cuentaText);
      // const centerX2 = (doc.internal.pageSize.width - textWidth2) / 2;
      // const bottomY = doc.internal.pageSize.height - 60; // Ajusta la posición vertical según tus necesidades
      // doc.text(cuentaText, centerX2, bottomY);

      // Agregar texto al final de la página
      let cuentaText3 = `_______________________`; // Tu texto aquí
      const textWidth3 = doc.getTextWidth(cuentaText3);
      const centerX3 = (doc.internal.pageSize.width - textWidth3) / 2;
      // const bottomY3 = doc.internal.pageSize.height - 30; // Ajusta la posición vertical según tus necesidades
      doc.text(cuentaText3, centerX3, tableHeight); 
      // Agregar texto al final de la página
      let cuentaText4 = `Firma`; // Tu texto aquí
      let textWidth4 = doc.getTextWidth(cuentaText4);
      let centerX4 = (doc.internal.pageSize.width - textWidth4) / 2;
      // let bottomY4 = doc.internal.pageSize.height - 20; // Ajusta la posición vertical según tus necesidades
      doc.text(cuentaText4, centerX4, tableHeight+5);
      // // Agregar texto al final de la página
      // cuentaText4 = `ASOCIACIÓN HONDUREÑA PARA EL DESARROLLO COMUNITARIO`; // Tu texto aquí
      // textWidth4 = doc.getTextWidth(cuentaText4);
      // centerX4 = (doc.internal.pageSize.width - textWidth4) / 2;
      // bottomY4 = doc.internal.pageSize.height - 70; // Ajusta la posición vertical según tus necesidades
      // doc.text(cuentaText4, centerX4, bottomY4);


      // Guardar el PDF
      doc.save(`${(this.cliente.nombres).trim()}${this.solicitud?.fechaPago}Solicitud.pdf`);
    },

    // addText(){
    //   let cuentaText = `Cuenta No. 15310167484 de BANRURAL`; // Tu texto aquí
    //   const textWidth2 = doc.getTextWidth(cuentaText);
    //   const centerX2 = (doc.internal.pageSize.width - textWidth2) / 2;
    //   const bottomY = doc.internal.pageSize.height -40; // Ajusta la posición vertical según tus necesidades
    //   doc.text(cuentaText, centerX2, bottomY);
    // },
    // handleImprimir() {
    //   const doc = new jsPDF();

    //   const text = 'PLAN DE PAGO';
    //   const textWidth = doc.getTextWidth(text);

    //   // Calcular la posición para centrar el texto
    //   const centerX = (doc.internal.pageSize.width - textWidth) / 2;
    //   const y = 10;

    //   // Colocar el texto centrado
    //   doc.text(text, centerX, y);

    //   doc.setFont('courier')

    //   const tableData = [];
    //   const tableInfoPrestamo = [];
    //   document.querySelectorAll('.datos-table tr').forEach((row) => {
    //     const rowData = [];
    //     // Iterar sobre las celdas de cada fila
    //     row.querySelectorAll('td').forEach((cell) => {
    //       rowData.push(cell.innerText);
    //     });

    //     tableData.push(rowData);
    //   });

    //   document.querySelectorAll('.datos-prestamo-table tr').forEach((row) => {
    //     const rowData = [];
    //     // Iterar sobre las celdas de cada fila
    //     row.querySelectorAll('td').forEach((cell) => {
    //       rowData.push(cell.innerText);
    //     });

    //     tableInfoPrestamo.push(rowData);
    //   });
    //   this.ejecutarPlanPagos()

    //   // Agregar la imagen
    //   const imgWidth = 50; // Ancho de la imagen en el documento
    //   const imgHeight = 50; // Alto de la imagen en el documento
    //   const imgX = (doc.internal.pageSize.width - imgWidth) / 2; // Posición X de la imagen
    //   const imgY = 20; // Posición Y de la imagen
    //   doc.addImage('check.jpg', 'PNG', imgX, imgY, imgWidth, imgHeight);

    //   // Crear la tabla en el PDF
    //   doc.autoTable({
    //     body: tableData,
    //     theme: 'striped', // Puedes cambiar el tema según tus preferencias
    //     styles: {
    //       halign: 'center',
    //     },
    //   });
    //   doc.autoTable({
    //     body: tableInfoPrestamo,
    //     theme: 'striped', // Puedes cambiar el tema según tus preferencias
    //     styles: {
    //       halign: 'left',
    //     },
    //   });
    //   doc.autoTable({
    //     head: [['No. Cuota', 'Fecha de Pago', 'Cuota Total', 'Interes', 'Capital', 'Saldo']],
    //     headStyles: { fillColor: [41, 128, 185] },
    //     body: this.planPagoToPrint.map(plan => [plan.noCuota, plan.fechaPago, plan.cuotaTotal, plan.interes, plan.capital, plan.saldo]),
    //     theme: 'grid',
    //     styles: {
    //       halign: 'center',
    //     },
    //   })

    //   // Guardar el PDF
    //   doc.save(`${(this.cliente.nombres).trim()}${this.solicitud?.fechaPago}Solicitud.pdf`);
    // },

    //Generar Plan de Pagos
    ejecutarPlanPagos() {
      this.solicitud.monto = parseFloat(this.solicitud.monto)
      const tablaBody = document.getElementById('tablaPlanPagos')
      if (this.monto === '' || this.solicitud.frecuenciaPago === '' || this.solicitud.interes === '' || this.solicitud.plazoPago === '') {
        tablaBody.innerHTML = `
          <tr>
            <td colspan=6 class="text-center text-danger">
              <h3>
                ¡Faltan datos!</td>  
              </h3>
          </tr>
        `
      } else {
        console.log('Frecuencia de Pago', this.solicitud.frecuenciaPago);
        this.generarPlanPago(this.stringToDate(this.solicitud.fechaPago ? this.solicitud.fechaPago : (new Date()).toISOString().slice(0, 10)), this.solicitud.monto, this.solicitud.frecuenciaPago, this.solicitud.interes, this.solicitud.plazoPago)
      }
    },
    //Convertir string a minuscula y sin espacios
    limpiarYMinusc(string) {
      // Eliminar espacios en blanco y convertir a minúsculas
      return string.replace(/\s/g, '').toLowerCase();
    },
    //Convertir numero negativo a 0
    convertirANoNegativo(numero) {
      // Si el número es negativo, convertirlo a cero
      if (numero < 0) {
        return 0;
      } else {
        return numero;
      }
    },

    generarPlanPago(
      fechaApertura,
      monto,
      periodoPago,
      interesAnual,
      plazo
    ) {
      console.log(fechaApertura)
      let fechaInicial = fechaApertura
      // Calcular la tasa de interés mensual
      let tasaAnual = interesAnual / 100;

      // Calcular el intervalo de tiempo entre pagos
      let duracionPago, periodo, noCuotas, interes, diasPeriodo, capital, saldo;

      // Imprimir la cabecera de la tabla

      // Generar el plan de pago
      saldo = monto;


      switch (this.limpiarYMinusc(periodoPago)) {
        case "mensual":
          duracionPago = 30 * 24 * 60 * 60 * 1000;
          fechaApertura = new Date(
            fechaApertura.getTime() + 30 * 24 * 60 * 60 * 1000
          );
          noCuotas = plazo;
          periodo = 12;
          diasPeriodo = 30.0;
          break;

        case "quincenal":
          duracionPago = 14 * 24 * 60 * 60 * 1000;
          fechaApertura = new Date(
            fechaApertura.getTime() + 15 * 24 * 60 * 60 * 1000
          );
          noCuotas = plazo * 2;
          periodo = 24;
          diasPeriodo = 15.0;
          break;

        case "semanal":

          duracionPago = 7 * 24 * 60 * 60 * 1000;
          fechaApertura = new Date(
            fechaApertura.getTime() + 7 * 24 * 60 * 60 * 1000
          );

          if (this.esCrediTrabajo(this.solicitudCompleta.producto)) {
            noCuotas = plazo * 4;
          } else {
            noCuotas = plazo * (4.3333333333);
          }

          periodo = 52.1;
          diasPeriodo = 7.0;
          break;

        case "alvencimiento":
          duracionPago = 1;
          fechaApertura = new Date(
            fechaApertura.getTime() + (plazo * 30) * 24 * 60 * 60 * 1000
          );
          noCuotas = 1;
          periodo = (30 / (plazo * 30)) * 12;
          diasPeriodo = plazo * 30;
          break;
        case "trimestral":
          duracionPago = 90 * 24 * 60 * 60 * 1000;
          fechaApertura = new Date(
            fechaApertura.getTime() + 90 * 24 * 60 * 60 * 1000
          );
          noCuotas = plazo / 3;
          periodo = 4;
          diasPeriodo = 90.0;
          break;
        case "semestral":
          duracionPago = 180 * 24 * 60 * 60 * 1000;
          fechaApertura = new Date(
            fechaApertura.getTime() + 180 * 24 * 60 * 60 * 1000
          );
          noCuotas = plazo / 6;
          periodo = 2;
          diasPeriodo = 180.0;
          break;
        case "anual":
          duracionPago = 360 * 24 * 60 * 60 * 1000;
          fechaApertura = new Date(
            fechaApertura.getTime() + 360 * 24 * 60 * 60 * 1000
          );
          noCuotas = plazo / 12;
          periodo = 1;
          diasPeriodo = 360.0;
          break;



        default:
          return;
      }

      // Generar el plan de pago
      let cuotaTotal = this.CalculaCuotaNivelada(
        monto,
        tasaAnual,
        plazo,
        periodo,
        diasPeriodo
      );
      this.planPagoToPrint = []
      const tablaBody = document.getElementById('tablaPlanPagos')
      tablaBody.innerHTML = ''
      tablaBody.innerHTML = `
        <tr>
          <th class="text-center align-middle" scope="row">0</td>
          <td class="text-center align-middle">${ this.dateFormat( fechaInicial.toISOString().slice(0, 10))}</td>
          <td class="text-center align-middle"></td>
          <td class="text-center align-middle"></td>
          <td class="text-center align-middle"></td>
          <td class="text-center align-middle">${this.formatoNumero(saldo)})} ${this.moneda}</td>
        </tr>
      `
      this.planPagoToPrint.push({
        noCuota: 0,
        fechaPago: this.dateFormat(fechaInicial.toISOString().slice(0, 10)),
        cuotaTotal: '',
        interes: '',
        capital: '',
        saldo: saldo.toFixed(2) + " " + this.moneda
      })

      if (this.solicitudCompleta.producto !== 'Credi Trabajo' && this.solicitudCompleta.producto !== 'Credi Trabajo 2') {
        this.cuotaSolicitud = cuotaTotal
        for (let i = 1; i <= Math.round(noCuotas); i++) {
          interes = ((saldo * tasaAnual) / 360) * diasPeriodo;
          capital = cuotaTotal - interes;
          saldo = saldo - capital;
          saldo = this.convertirANoNegativo(saldo)
          // Imprimir la fila de la tabla
          tablaBody.innerHTML += `
          <tr>
            <th class="text-center align-middle" scope="row">${i}</th>
            <td class="text-center align-middle">${this.dateFormat(fechaApertura.toISOString().slice(0, 10)) }</td>
            <td class="text-center align-middle">${Number(cuotaTotal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
            <td class="text-center align-middle">${Number(interes).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
            <td class="text-center align-middle">${Number(capital).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
            <td class="text-center align-middle">${Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
            </tr>
          `

          this.planPagoToPrint.push({
            noCuota: i,
            fechaPago: this.dateFormat(fechaApertura.toISOString().slice(0, 10)),
            cuotaTotal: Number(cuotaTotal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })+ " " + this.moneda,
            interes: Number(interes).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })+ " " + this.moneda,
            capital: Number(capital).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + this.moneda,
            saldo: Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + this.moneda
          })
          this.fechaVencimiento = fechaApertura
          fechaApertura = new Date(fechaApertura.getTime() + duracionPago);

        }
      } else {
        //Creditrabajo
        if(this.creditrabajo2){
          noCuotas = Math.round(noCuotas)
        }

        console.log("El numero de Cuotas es: ", noCuotas);
        cuotaTotal = monto / noCuotas;
        this.cuotaSolicitud = cuotaTotal
        for (let i = 1; i <= Math.round(noCuotas); i++) {
          interes = ((saldo * tasaAnual) / 360) * diasPeriodo;
          capital = cuotaTotal;
          saldo = this.convertirANoNegativo(saldo - capital);
          // Imprimir la fila de la tabla
          tablaBody.innerHTML += `
          <tr>
            <th class="text-center align-middle" scope="row">${i}</th>
            <td class="text-center align-middle">${this.dateFormat(fechaApertura.toISOString().slice(0, 10))}</td>
            <td class="text-center align-middle">${cuotaTotal.toFixed(2)} ${this.moneda}</td>
            <td class="text-center align-middle"></td>
            <td class="text-center align-middle">${capital.toFixed(2)} ${this.moneda}</td>
            <td class="text-center align-middle">${Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${this.moneda}</td>
            </tr>
            `
          this.planPagoToPrint.push({
            noCuota: i,
            fechaPago: this.dateFormat(fechaApertura.toISOString().slice(0, 10)),
            cuotaTotal: Number(cuotaTotal).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })+ " " + this.moneda,
            interes: '',
            capital: Number(capital).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " "+  this.moneda,
            saldo: Number(saldo).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + this.moneda
          })
          this.fechaVencimiento = fechaApertura
          fechaApertura = new Date(fechaApertura.getTime() + duracionPago);

        }
      }
    }
    ,
    esCrediTrabajo(producto) {
      // Convertir producto a minúsculas para normalizar
      producto = producto.trim().toLowerCase();

      // Lista de valores normalizados
      const creditrabajo = [
        "creditrabajo",
        "credi trabajo",
        "credi trabajo ",
        "credi trabajo",
        "Credi Trabajo",
        "creditrabajo ",
        "creditrabajo ",
        "credi trabajo",
        "creditrabajo",
      ];

      // Verificar si el producto está en la lista de valores normalizados
      return creditrabajo.includes(producto);
    },


    // CalculaCuotaNivelada calcula la cuota nivelada de un préstamo
    CalculaCuotaNivelada(
      montoPrestamo,
      tasaInteres,
      periodos,
      periodo, 
      diasPeriodo
    ) {
      if (periodo === 24) {
        periodos *= 2;
      } else if (periodo === 52.1) {
        periodos *= 4.33;
        periodos = Math.round(periodos);
      } else if (periodo === (30 / (periodos * 30)) * 12) {
        periodos = 1
      } else if (periodo === 4) {
        periodos /= 3;
        // periodos = Math.round(periodos);
      } else if (periodo === 2) {
        periodos /= 6;
        // periodos = Math.round(periodos);
      } else if (periodo === 1) {
        periodos /= 12;
        // periodos = Math.round(periodos);
      }

      let tasaMensual = (tasaInteres / 360) * diasPeriodo;
      tasaMensual = Math.round(tasaMensual * 10000000000) / 10000000000;

      let cuota =
        (montoPrestamo * tasaMensual) / (1 - Math.pow(1 + tasaMensual, -periodos));
      return cuota;
    },

    stringToDate(dateString) {
      // Cadena con formato mm/dd/yyyy
      // Diseño que coincide con el formato de la cadena
      let date = new Date(dateString.split("/").reverse().join("-"));
      return date;
    },

    //Enviar Datos
    submitHandler() {
      //

      this.solicitudCompleta.integranteSolicitud++;

      this.solicitud.montoDesembolsar = parseFloat(this.montoDesembolsar)

      if (this.solicitudCompleta.solicitudesObject.length >= this.solicitudCompleta.integranteSolicitud) {
        //Se aumenta el no de integrante que se encuentra por segunda vez

        this.solicitud = this.restaurarSolicitud()
        this.cliente = this.solicitudCompleta.integrantes[this.solicitudCompleta.integranteSolicitud];

      } else {

        //Se aumenta el no de integrante que se encuentra por segunda vez
        // this.solicitudCompleta.integranteSolicitud++;
        //Agregamos El producto a la solicitud
        this.solicitud.producto = this.solicitudCompleta.producto
        //Agregamos el Id del cliente a la solicitud
        this.solicitud.clienteId = this.cliente.id;
        //Agregamos el id del Grupo solidario a la solicitud 
        this.solicitud.idGrupoSolidario = this.solicitudCompleta.idGrupoSolidario
        //Convertimos en Enteros y en Float
        this.solicitud.plazoPago = parseFloat(this.solicitud.plazoPago, 10)
        this.solicitud.monto = parseFloat(this.solicitud.monto, 10)

        this.solicitud.integrantesGruposSolidario.monto1 = parseFloat(this.solicitud.integrantesGruposSolidario.monto1, 10)
        this.solicitud.integrantesGruposSolidario.monto2 = parseFloat(this.solicitud.integrantesGruposSolidario.monto2, 10)
        this.solicitud.integrantesGruposSolidario.monto3 = parseFloat(this.solicitud.integrantesGruposSolidario.monto3, 10)
        this.solicitud.integrantesGruposSolidario.monto4 = parseFloat(this.solicitud.integrantesGruposSolidario.monto4, 10)
        this.solicitud.integrantesGruposSolidario.monto5 = parseFloat(this.solicitud.integrantesGruposSolidario.monto5, 10)
        this.solicitud.integrantesGruposSolidario.cuota1 = parseFloat(this.solicitud.integrantesGruposSolidario.cuota1, 10)
        this.solicitud.integrantesGruposSolidario.cuota2 = parseFloat(this.solicitud.integrantesGruposSolidario.cuota2, 10)
        this.solicitud.integrantesGruposSolidario.cuota3 = parseFloat(this.solicitud.integrantesGruposSolidario.cuota3, 10)
        this.solicitud.integrantesGruposSolidario.cuota4 = parseFloat(this.solicitud.integrantesGruposSolidario.cuota4, 10)
        this.solicitud.integrantesGruposSolidario.cuota5 = parseFloat(this.solicitud.integrantesGruposSolidario.cuota5, 10)
        // this.solicitud.interes = parseInt(this.solicitud.interes, 10)

        this.solicitud.planInversion.val1 = parseFloat(this.solicitud.planInversion.val1, 10)
        this.solicitud.planInversion.val2 = parseFloat(this.solicitud.planInversion.val2, 10)
        this.solicitud.planInversion.val3 = parseFloat(this.solicitud.planInversion.val3, 10)
        this.solicitud.planInversion.val4 = parseFloat(this.solicitud.planInversion.val4, 10)
        this.solicitud.planInversion.val5 = parseFloat(this.solicitud.planInversion.val5, 10)

        for (let i = 0; i < this.solicitud.filas.length; i++) {
          this.solicitud.filas[i].valor = parseFloat(this.solicitud.filas[i].valor);

        }

        this.solicitud.coordinador = this.solicitudCompleta.coordinador

        //Agrego solicitud a Payload y la solicitud a solicitudes
        let payload = this.solicitud;
        this.solicitudCompleta.solicitudesObject.push(this.solicitud)
        payload = {
          ...payload,
          idUsuario: Security.getUser()[0].user.id,
        }

        ////////////////////////////
        //Si el integrante por el que va es igual total de integrantes del grupo solidario
        if (
          this.solicitudCompleta.integranteSolicitud ===
          this.solicitudCompleta.integrantes.length
        ) {
          this.solicitudCompleta.integranteSolicitud--;

          console.log("Datos que se envian", payload);

          Swal.fire({
            title: '¿Estás Seguro de que deseas agregar la solicitud?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, Creala!'
          }).then((result) => {
            if (result.isConfirmed) {

              //Agregar Solicitudes
              this.solicitudCompleta.integranteSolicitud = 0;

              fetch(`${process.env.VUE_APP_API_URL}/admin/solicitudes/save`, Security.requestOptions(payload))
                .then((response) => response.json())
                .then((data) => {
                  if (data.error) {
                    this.$emit('error', data.message)
                    Swal.fire({
                      // position: 'top-end',
                      icon: "error",
                      title: data.message,
                      showConfirmButton: false,
                      timer: 1500,
                    });
                  } else {
                    //Agregar id de solicitud
                    solicitudCompleta.solicitudes.push(data.data.id)
                    //Info Correcta
                    Swal.fire({
                      // position: 'top-end',
                      icon: "success",
                      title: "Se ha Guardado Correctamente la Solicitud",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                    this.solicitudCompleta.integrantes = []
                    this.solicitudCompleta.producto = ""
                    this.solicitudCompleta.idGrupoSolidario = 0
                    this.solicitudCompleta.dniIntegrantes = 0
                    this.solicitudCompleta.nombreGrupoSolidario = ""
                    this.solicitudCompleta.integranteSolicitud = 0
                    this.solicitudCompleta.solicitudes = []
                    this.solicitudCompleta.solicitudesObject = []
                    Swal.fire({
                      // position: 'top-end',
                      icon: "success",
                      title: "Se ha terminado de crear solicitud",
                      showConfirmButton: false,
                      timer: 1500,
                    });
                    //Reestablecer Página
                    window.onbeforeunload = null; // Desactiva la confirmación de navegación
                    this.final = true;
                    router.push("/solicitud");
                    location.reload();
                    window.location.href = "/solicitud";
                  }
                })
                .catch((error) => {
                  this.$emit('error', error);
                })






            }
          })

          /////////////////////////////////////
          //Else Caso contrario que el numero no sea igual al total de intergrantes
        } else {
          //Se guardan los datos del integrate por el que va segun el index que es integranteSolicitud
          this.cliente = this.solicitudCompleta.integrantes[this.solicitudCompleta.integranteSolicitud];

          //Peticion Fetch
          Swal.fire({
            title: '¿Estás Seguro de que deseas modificar la solicitud?',
            icon: 'success',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Guardar Cambios!'
          }).then((result) => {
            if (result.isConfirmed) {
              fetch(`${process.env.VUE_APP_API_URL}/admin/solicitudes/save`, Security.requestOptions(payload))
                .then((response) => response.json())
                .then((data) => {
                  if (data.error) {
                    //Mensaje Alerta Error
                    Swal.fire({
                      icon: "error",
                      title: data.message,
                      showConfirmButton: false,
                      timer: 1500,
                    });

                  } else {

                    //Agregar id de solicitud
                    solicitudCompleta.solicitudes.push(data.data.id)

                    //Alerta de que guardo correctamente
                    Swal.fire({
                      icon: "success",
                      title: "Se ha Guardado Correctamente la Solicitud",
                      showConfirmButton: false,
                      timer: 1500,
                    });

                  }
                })
                .catch((error) => {
                  this.$emit('error', error);
                })

              //Se usa funcion que no quita la validación de Seguridad que se perderan los datos  
              this.quitarValidacion()
              this.solicitud = this.restaurarSolicitud()
              //Se resetea el Formulario
              document.getElementById("cliente-form").reset();

              if (this.solicitudCompleta.producto !== "Escalonado") {
                router.push("/solicitud");
                location.reload()

              }

            } else {
              if (this.solicitudCompleta.producto !== "Escalonado") {
                router.push("/solicitud");
                location.reload()

              }

            }
          })
        }
      }
    },


    //Recibir Datos de Otros componentes
    recibirDatosDelHijo(datos) {
      this.cliente = datos; // Manejar los datos recibidos del hijo
      //No funciona
    },


    //Navegar entre Páginas

    //NextPage: Funcion siguiente pagina NO FUNCIONAL POSIBLE BORRAR
    nextPage() {

      if (this.solicitudCompleta.solicitudes[this.solicitudCompleta.integranteSolicitud]) {
        console.log();
      } else {
        this.submitHandler();
      }
      //Se llama la funcion Submit Handler para subir la solicitud



      //Este es cuando es uno que no sea mipyme
      if (
        this.solicitudCompleta.integrantes.length >
        this.solicitudCompleta.integranteSolicitud + 1
      ) {
        this.solicitudCompleta.integranteSolicitud++;

        //Guardar solicitud de integrante

        //Validar Solicitud
      }
    },
    buscarIntegrantePorDni(dni, integrante) {
      if (Array.from(dni).length == 13) {
        const dniFormatToFetch = dni.replace(/-/g, "");
        fetch(
          `${process.env.VUE_APP_API_URL}/admin/clientes/dni/${dniFormatToFetch}`,
          Security.requestOptions("")
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              this.$emit("error", data.message);
              switch (integrante) {
                case 1:
                  this.nomInt1 = "El Integrante no se encuentra en nuestra base de datos, quita los guiones o agrega al beneficiario"
                  break;
                case 2:
                  this.nomInt2 = "El Integrante no se encuentra en nuestra base de datos, quita los guiones o agrega al beneficiario"
                  break;
                case 3:
                  this.nomInt3 = "El Integrante no se encuentra en nuestra base de datos, quita los guiones o agrega al beneficiario"
                  break;
                case 4:
                  this.nomInt4 = "El Integrante no se encuentra en nuestra base de datos, quita los guiones o agrega al beneficiario"
                  break;
                case 5:
                  this.nomInt5 = "El Integrante no se encuentra en nuestra base de datos, quita los guiones o agrega al beneficiario"
                  break;

              }
            } else {
              // this.cliente = data.data;
              // this.solicitudCompleta.integrantes.push(data.data)
              switch (integrante) {
                case 1:
                  this.nomInt1 = data.data.nombres + (data.data.apellidos ? (" " + data.data.apellidos) : "")
                  this.solicitud.integrantesGruposSolidario.nombre1 = this.nomInt1
                  this.solicitud.integrantesGruposSolidario.rtn1 = data.data.rtn
                  this.solicitud.integrantesGruposSolidario.infoBeneficiario1 = data.data

                  break;
                case 2:
                  this.nomInt2 = data.data.nombres + (data.data.apellidos ? (" " + data.data.apellidos) : "")
                  this.solicitud.integrantesGruposSolidario.nombre2 = this.nomInt2
                  this.solicitud.integrantesGruposSolidario.rtn2 = data.data.rtn
                  this.solicitud.integrantesGruposSolidario.infoBeneficiario2 = data.data
                  break;
                case 3:
                  this.nomInt3 = data.data.nombres + (data.data.apellidos ? (" " + data.data.apellidos) : "")
                  this.solicitud.integrantesGruposSolidario.nombre3 = this.nomInt3
                  this.solicitud.integrantesGruposSolidario.rtn3 = data.data.rtn
                  this.solicitud.integrantesGruposSolidario.infoBeneficiario3 = data.data
                  break;
                case 4:
                  this.nomInt4 = data.data.nombres + (data.data.apellidos ? (" " + data.data.apellidos) : "")
                  this.solicitud.integrantesGruposSolidario.nombre4 = this.nomInt4
                  this.solicitud.integrantesGruposSolidario.rtn4 = data.data.rtn
                  this.solicitud.integrantesGruposSolidario.infoBeneficiario4 = data.data
                  break;
                case 5:
                  this.nomInt5 = data.data.nombres + (data.data.apellidos ? (" " + data.data.apellidos) : "")
                  this.solicitud.integrantesGruposSolidario.nombre5 = this.nomInt5
                  this.solicitud.integrantesGruposSolidario.rtn5 = data.data.rtn
                  this.solicitud.integrantesGruposSolidario.infoBeneficiario5 = data.data
                  break;

              }


              console.log(data.data);
              this.$emit("datos-hijo-al-padre", data.data);
            }
          })
          .catch((error) => {
            this.$emit("error", error);

          });
      } else {
        // this.cliente = "";
        // this.solicitudCompleta.integrantes.pop()
        // this.mensajeDni = ""
        // dni = document.getElementById('dni');
        // dni.classList.remove('is-invalid')
        // dni.classList.add('was-validated')
      }
    },

    previousPage() {

      if (this.solicitudCompleta.integranteSolicitud > 0) {
        //Ir para atras en las solicitudes

        this.solicitudCompleta.integranteSolicitud--;
        this.cliente =
          this.solicitudCompleta.integrantes[
          this.solicitudCompleta.integranteSolicitud
          ];
        this.solicitud =
          this.solicitudCompleta.solicitudesObject[
          this.solicitudCompleta.integranteSolicitud
          ]

        // router.push(`/solicitud/integrante/${this.solicitudCompleta.solicitudes[this.solicitudCompleta.integranteSolicitud].id}`);

      } else if (this.solicitudCompleta.producto !== "Escalonado") {
        router.push("/solicitud");
      } else {
        router.push("/solicitud/grupo-solidario/0");
      }
    },
    formatDNI(dni) {
      const cleanedValue = dni.replace(/-/g, "");

      // Aplica el formato con guiones
      let formattedValue = "";
      for (let i = 0; i < cleanedValue.length; i++) {
        if (i === 4 || i === 8) {
          formattedValue += "-";
        }
        formattedValue += cleanedValue[i];
      }

      // Actualiza el valor del input
      return formattedValue;
    },
    formatInput() {
      // Elimina todos los guiones existentes en el valor actual
      const cleanedValue = this.dni.replace(/-/g, "");

      // Aplica el formato con guiones
      let formattedValue = "";
      for (let i = 0; i < cleanedValue.length; i++) {
        if (i === 4 || i === 8) {
          formattedValue += "-";
        }
        formattedValue += cleanedValue[i];
      }

      // Actualiza el valor del input
      this.dni = formattedValue;
    },
    async quitarValidacion() {
      await new Promise(resolve => setTimeout(resolve, 2000));
      const inputElement = document.querySelector('.form-control');

      // Elimina la clase CSS que agrega los estilos no deseados
      inputElement.classList.remove('was-validated');
      inputElement.classList.remove('is-invalid');

      // Sobrescribe los estilos en línea para eliminar los estilos no deseados
      inputElement.style.border = 'none';
      inputElement.style.paddingRight = 'initial';
      inputElement.style.backgroundImage = 'none';
      inputElement.style.backgroundRepeat = 'initial';
      inputElement.style.backgroundPosition = 'initial';
      inputElement.style.backgroundSize = 'initial';
    },

    restaurarSolicitud() {
      const solicitud = {
        ID: 0,
        clienteId: 0,
        producto: "",
        destinoCreditos: [],
        tiempoVivienda: "",
        materialVivienda: "",
        noPersonasVivivenda: "",
        noFamilias: "",
        noTrabajan: "",
        serviciosPublico: [],
        noDependientes: "",
        noBuscanEmpleo: "",
        planInversion: {
          req1: "",
          req2: "",
          req3: "",
          req4: "",
          req5: "",
          desc1: "",
          desc2: "",
          desc3: "",
          desc4: "",
          desc5: "",
          val1: 0,
          val2: 0,
          val3: 0,
          val4: 0,
          val5: 0,
        },
        referencias: {
          nom1: "",
          nom2: "",
          nom3: "",
          nom4: "",
          par1: "",
          par2: "",
          par3: "",
          par4: "",
          dir1: "",
          dir2: "",
          dir3: "",
          dir4: "",
          cel1: 0,
          cel2: 0,
          cel3: 0,
          cel4: 0,
        },
        sector: [],
        gruposEspeciales: [],
        idGrupoSolidario: 0,

        fechaPago: "",
        monto: 0,
        frecuenciaPago: "",
        interes: 12,
        tipoPlazo: "",
        plazoPago: 0,
        estado: 'pendiente',
        sucursal: ''
      };

      return solicitud;
    },
  },
  computed: {
    noIntegregante() {
      switch (this.solicitudCompleta.integranteSolicitud) {
        case 0:
          return "Primer";
        case 1:
          return "Segundo";
        case 2:
          return "Tercer";
        case 3:
          return "Cuarto";
        case 4:
          return "Quinto";
        default:
          return "Número fuera de rango";
      }
    },
    sumaTablaInverciones() {



      let suma = (parseFloat(this.solicitud.planInversion.val1) +
        parseFloat(this.solicitud.planInversion.val2) +
        parseFloat(this.solicitud.planInversion.val3) +
        parseFloat(this.solicitud.planInversion.val4) + parseFloat(this.solicitud.planInversion.val5))

      for (let i = 0; i < this.solicitud.filas.length; i++) {
        suma += parseFloat(this.solicitud.filas[i].valor);

      }
      return suma
    },
    validarGrupo() {
      if (this.solicitudCompleta.producto === `Escalonado`) {
        return true;
      } else return false;
    },
    validarMiPyme() {
      if (this.solicitudCompleta.producto !== `Escalonado`) {
        return true;
      } else return false;
    },
  },
  watch: {
    comisionDesembolso: function (comisionDesembolso) {
      if (!this.creditrabajo2()) {
        this.totalComisionDesembolso = this.solicitud.monto * (comisionDesembolso / 100)
        this.montoNeto = this.solicitud.monto - this.totalComisionDesembolso - this.totalComisionAsistenciaTecnica - this.totalTasaSeguridad

      }

    },
    comisionAsistenciaTecnica: function (comisionAsistenciaTecnica) {
      if (!this.creditrabajo2()) {
        this.totalComisionAsistenciaTecnica = this.solicitud.monto * (comisionAsistenciaTecnica / 100)
        this.montoNeto = this.solicitud.monto - this.totalComisionDesembolso - this.totalComisionAsistenciaTecnica - this.totalTasaSeguridad

      }
    },
    tasaSeguridad: function (tasaSeguridad) {
      if (!this.creditrabajo2()) {
        this.totalTasaSeguridad = this.solicitud.monto * (tasaSeguridad / 100)
        this.montoNeto = this.solicitud.monto - this.totalComisionDesembolso - this.totalComisionAsistenciaTecnica - this.totalTasaSeguridad

      }
    },
    montoDesembolsar: function (monto) {
      console.log(monto);
      this.solicitud.montoDesembolsar = parseFloat(monto)
    },
    dni(dni) {
      if (Array.from(dni).length == 15) {
        const dniFormatToFetch = dni.replace(/-/g, "");
        fetch(
          `${process.env.VUE_APP_API_URL}/admin/clientes/dni/${dniFormatToFetch}`,
          Security.requestOptions("")
        )
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              this.$emit("error", data.message);
            } else {
              this.cliente = data.data;
              this.solicitudCompleta.integrantes.push(data.data)
              this.$emit("datos-hijo-al-padre", data.data);
            }
          })
          .catch((error) => {
            this.$emit("error", error);
          });
      } else {
        this.cliente = "";
        this.solicitudCompleta.integrantes.pop()
        // this.mensajeDni = ""
        // dni = document.getElementById('dni');
        // dni.classList.remove('is-invalid')
        // dni.classList.add('was-validated')
      }
    },
    'solicitud.integrantesGruposSolidario.int1': function (dni) {
      console.log(dni);
      this.buscarIntegrantePorDni(dni, 1)
      
    },
    'solicitud.integrantesGruposSolidario.int2': function (dni) {

      this.buscarIntegrantePorDni(dni, 2)

    },
    'solicitud.integrantesGruposSolidario.int3': function (dni) {

      this.buscarIntegrantePorDni(dni, 3)

    },
    'solicitud.integrantesGruposSolidario.int4': function (dni) {

      this.buscarIntegrantePorDni(dni, 4)

    },
    'solicitud.integrantesGruposSolidario.int5': function (dni) {

      this.buscarIntegrantePorDni(dni, 5)

    },




    'solicitud.garantia': function (garantia) {

      const garantiaContent = document.getElementById('info_garantia')
      garantiaContent.innerHTML = ''

      if (garantia === "Garantía Fiduciaria") {
        garantiaContent.innerHTML = `
        <ul class="list-group">
          <li class="list-group-item">a)	Documento Nacional de Identificación DNI (Copia Legible).</li>
          <li class="list-group-item">b)	Registro Tributario Nacional o RTN (Copia Legible).</li>
          <li class="list-group-item">c)	Recibo público legible (con un mes de antigüedad).</li>
          <li class="list-group-item">d)	Constancia de Trabajo con deducciones y/o Váucher de pago de los últimos dos meses.</li>
          <li class="list-group-item">e)	Croquis de ubicación de la vivienda y Trabajo, con al menos cinco puntos de referencia.</li>
          <li class="list-group-item">f)	Fotografías de la Vivienda y del Trabajo.  </li>
          <li class="list-group-item">g)	Autorización Irrevocable de Deducción por Planilla (Si aplica). </li>
        </ul>
        `
      } else if (garantia === "Aval Comerciante") {
        garantiaContent.innerHTML = `
        <ul class="list-group">
          <li class="list-group-item">a)	Documento Nacional de Identificación DNI (Copia Legible)</li>
          <li class="list-group-item">b)	Registro Tributario Nacional o RTN (Copia Legible).</li>
          <li class="list-group-item">c)	Relación de ingresos/egresos.</li>
          <li class="list-group-item">d)	Recibo Publico legible (con un mes de antigüedad).</li>
          <li class="list-group-item">e)	Croquis de ubicación de la vivienda y Negocio, con al menos cinco puntos de referencia.</li>
          <li class="list-group-item">f)	Fotografías de la Vivienda y del Negocio. (Debe aparecer OM responsables del crédito)</li>
          <li class="list-group-item">g)	Constancia de verificación de negocio emitida por el OM responsables del Crédito.</li>
          <li class="list-group-item">h)	Referencias familiares o personales.</li>
        </ul>
        `
      } else if (garantia === "Garantía Mobiliaria") {
        garantiaContent.innerHTML = `
        <ul class="list-group">
          <li class="list-group-item">a)	Cotización Original del Equipo y Mobiliario nuevo a adquirir. (Este equipo debe formar parte de la garantía del crédito, sin menoscabo a las demás garantías solicitadas de acuerdo con el análisis de riesgo que presente el crédito).</li>
          <li class="list-group-item">b)	Presentar Factura original o Declaración Jurada de Propiedad del equipo propuesto   en Garantía.</li>
          <li class="list-group-item">c)	Contrato con Garantía Mobiliario.</li>
          <li class="list-group-item">d)	Contrato de subrogación de pagos (Hasta el 80% del valor del contrato).</li>
          <li class="list-group-item">e)	Declaración de garantías mobiliarias y Maquinaria otorgando hasta un máximo de 70% sobre el valor estimado.</li>
        </ul>
        `
      } else if (garantia === "Garantía Prendaria") {
        garantiaContent.innerHTML = `
        <ul class="list-group">
          <li class="list-group-item">a)	Avalúo del Vehículo, realizado por un taller mecánico autorizado por el Comité de Crédito.</li>
          <li class="list-group-item">b)	Documentos Originales del Vehículo.</li>
          <li class="list-group-item">c)	Traspaso del vehículo a favor de AHDECO.</li>
          <li class="list-group-item">d)	Solo se aceptarán vehículos de hasta 10 años de antigüedad.</li>
          <li class="list-group-item">e)	La relación crédito/garantía será hasta del 70% del valor de avalúo.</li>
        </ul>
        `
      } else if (garantia === "Garantía Hipotecaria") {
        garantiaContent.innerHTML = `
        <ul class="list-group">
          <li class="list-group-item">a)	Fotocopia de escritura del inmueble que dejará en garantía.</li>
          <li class="list-group-item">b)	Información soporte de la investigación registral en el sistema unificado de registro, para validar el estatus del inmueble. En los casos que el inmueble no tiene matricula en el sistema, se solicitará una constancia de liberación de gravamen.</li>
          <li class="list-group-item">c)	El dominio del inmueble debe ser "Dominio Pleno".</li>
          <li class="list-group-item">d)	Recibo de pago de bienes inmueble vigente o estado de cuenta que refleje que el pago está al día (en la Alcaldía Municipal).</li>
          <li class="list-group-item">e)	Constancia Original de valor catastral emitido por la Alcaldía Municipal.<li>
          <li class="list-group-item">f)	Avalúo del bien inmueble (Elaborado por valuador vigente y autorizado por AHDECO e inscrito en la Comisión Nacional de Bancos y Seguros).<li>
          <li class="list-group-item">g)	Recibo de la ENEE y SANAA del inmueble propuesto en garantía.<li>
          <li class="list-group-item">h)	Para inmuebles urbanos la relación préstamo/garantía será hasta un 80% del valor del avalúo y para inmuebles rurales hasta el 70% del valor del avalúo.<li>
          <li class="list-group-item">i)	Copia del Recibo de presentación en el registro de la Propiedad del Testimonio de escritura con la hipoteca del inmueble a favor de AHDECO.<li>
        </ul>
        `
      } else if (garantia === "Grupo Solidario") {
        garantiaContent.innerHTML = `
        <ul class="list-group">
          <li class="list-group-item">a)	Deberá estar conformado por grupos de beneficiarios de 2 a 5 integrantes, formados de manera voluntaria y que sean afines; los cuales deberán avalarse mutuamente y acceder a un crédito.</li>
          <li class="list-group-item">b)	Estar constituidos formalmente por Acta de constitución de Grupo (Ver anexo), debidamente firmada por los integrantes en la cual los integrantes manifiesten tener confianza entre sí y avalarse solidariamente.</li>
          <li class="list-group-item">c)	Los integrantes del grupo solidario deben ser ciudadanos hondureños por nacimiento naturalizados, con domicilio en el territorio nacional y encontrarse en el pleno ejercicio de sus derechos civiles.</li>
          <li class="list-group-item">d)	Se podrá otorgar créditos a jóvenes comprendidos entre los 18 y 21 años en cualquiera de las formas siguientes:
              <ul class="list-group">
                <li class="list-group-item">  a.	Quienes se hayan emancipado, ya sea por constituirse como comerciante o por haber contraído matrimonio. (Presentar constancia extendida por el RNP). </li>
                  <li class="list-group-item">  b.	Con autorización de los padres o representantes legales en instrumento público o interviniendo el mismo expresa y directamente en el acto (firmando el contrato de crédito).</li>
                    <li class="list-group-item">  c.	Tener un Coordinador o Presidente del Grupo Solidario el cual deberá ser elegido democráticamente por los demás integrantes.</li>
              </ul>
            </li>
        </ul>
        `
      }

    },
    'solicitud.estado': function (estado) {
      const userRoles = Security.getUserRoles();
      const formulario = document.getElementById('cliente-form');
      const inputs = formulario.querySelectorAll('input')
      const selects = formulario.querySelectorAll('select')
      if (estado === 'Aprobado' || estado === 'Desembolsado') {
        inputs.forEach(input => {
          input.disabled = true;
        })
        selects.forEach(select => {
          select.disabled = true;
        })
        document.getElementById('fecha').disabled = false
      }
      if (estado === 'Aprobado' || estado === 'Desembolsado') {

        selects.forEach(select => {
          select.name === 'garantia' ? select.disabled = false : select.disabled = true;
        })
        document.getElementById('fecha').disabled = false
      }
      if (userRoles[0]?.some(role => role.includes('Modo Thanos')) === true) {
        document.getElementById('fecha').disabled = true
        inputs.forEach(input => {
          input.disabled = false;
        })
        selects.forEach(select => {
          select.disabled = false;
        })
      }
    },
    'solicitud.plazoPago': function (plazo) {
      console.log(plazo);
      this.onBlur()
      switch (plazo) {
        case 1:
          this.solicitud.interes = 12
          this.comisionDesembolso = 4.27
          this.comisionAsistenciaTecnica = 3.80
          this.tasaSeguridad = 0.40
          break;
        case 2:
          this.solicitud.interes = 8.16
          this.comisionDesembolso = 4.08
          this.comisionAsistenciaTecnica = 3.62
          this.tasaSeguridad = 0.40
          break;
        case 3:
          this.solicitud.interes = 22.56
          this.comisionDesembolso = 3.9
          this.comisionAsistenciaTecnica = 3.47
          this.tasaSeguridad = 0.40
          break;
        case 4:
          this.solicitud.interes = 28.68
          this.comisionDesembolso = 3.74
          this.comisionAsistenciaTecnica = 3.32
          this.tasaSeguridad = 0.40
          break;
        case 5:
          this.solicitud.interes = 31.56
          this.comisionDesembolso = 3.59
          this.comisionAsistenciaTecnica = 3.19
          this.tasaSeguridad = 0.40
          break;
        case 6:
          this.solicitud.interes = 33
          this.comisionDesembolso = 3.9
          this.comisionAsistenciaTecnica = 3.47
          this.tasaSeguridad = 0.40
          break;
        default:
          this.solicitud.interes = 12
          this.comisionDesembolso = 4.27
          this.comisionAsistenciaTecnica = 3.80
          this.tasaSeguridad = 0.40
          break;
      }



    },

  },
};
</script>

<style scoped>
.custom-table {
  outline: none;
  border: none;
  margin: 0px;
  padding: 0px;
  border-collapse: collapse;

  >tbody>tr>td {
    margin: 0px;
    padding: 0px;
    border: none;
    vertical-align: middle;

    .control {
      text-align: left;
      font-size: 1rem;
    }
  }
}

.btnOptionSolicitud {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.card {


  border-radius: 12px;
  /* border-left-color: #4285f4; */
  border-top: 5px solid #ff910088;
  border-left: 5px solid #4286f467;
}

/* .form-control {
  border-radius: 10px;
  height: 70%;
  
} */

/* .form-control:focus {
  border-color: #ff9100;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
} */

/* Sombra */
.col-form-label {
  margin: 0px !important;
}

.form-group {
  margin-bottom: 0px;
}

.form-group {
  margin-block: 8px;
}

.col-form-label {
  padding-left: 10px;
  padding-right: 5px;
}

.card-description {
  margin-block: 15px;
}


.btnn {
  /* background-color: aqua; */
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.btnn p {
  margin-bottom: 0px;
  color: white;
}

.plan-inversión {
  /* width: 50%; */
  /* border-collapse: collapse; */
  margin-bottom: 20px;
}

.plan-inversión,
th,
td {
  border: 1px solid black;
  text-align: center;
}

th,
td {
  padding: 10px;
  vertical-align: top;
  /* Alinea el contenido en la parte superior de las celdas */
}

.input-plan {
  width: 100%;
  box-sizing: border-box;
  border: none;
  /* Elimina los bordes de los inputs */
  outline: none;
  /* Elimina el contorno al hacer clic en los inputs */
}

.table-container {
  width: 70%;
}

.custom-table {
  border-collapse: collapse;
  width: 100%;
}

.custom-table th,
.custom-table td {
  border: 1px solid black;
  padding: 10px;
  text-align: center;
  vertical-align: top;
}

.custom-input {
  width: 100%;
  box-sizing: border-box;
  border: none;
  outline: none;
}
</style>

<style>
.carta {
  border-radius: 12px;

  /* border-left-color: #4285f4; */
  /* border-top: 5px solid #00ff0d88; */
  /* border-left: 5px solid #ffea0667; */
  >.row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
}

@media screen and (max-width: 768px) {
  .carta {
    >.row {
      flex-direction: column;

      >div {
        width: 100%;
      }
    }
  }

}

.fm {
  border-radius: 10px;
  height: 70%;
}

.fm:focus {
  border-color: #ff9100;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  /* Sombra */
}

.pad {
  /* padding-left: 5px; */
  padding-inline: 5px !important;
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  -ms-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes bounce {

  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {

  0%,
  20%,
  53%,
  80%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transition-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}
</style>


<!-- Estilos tabla de amortizacion -->

<style>
#contenedor-plan,
#contenedorTabla {
  background-color: #FFF;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  overflow: auto;
  width: 100%;
  max-width: 100%;

  >.header {
    width: 100%;

  }
}

/* #contenedorTabla {
  width: 100%;
  max-height: 80%;
  overflow-y: hidden;
  overflow-x: auto;
} */


.table-plan {
  border: 2px solid royalblue;
  border-collapse: collapse;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  font-family: inherit;
  font-size: 0.9em;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.table-plan thead tr {
  background: royalblue;
  color: white;
  text-align: left;
  font-weight: bold;
}

.table-plan thead,
td {
  padding: 12px 15px;
}

.table-plan tbody tr {
  border-bottom: 1px solid #F0F0F0;
}

.table-plan tbody tr:nth-last-of-type(even) {
  background-color: #F3F3F3;
}

.table-plan tbody tr:last-of-type {
  border-bottom: 2px solid royalblue;
}

.table-plan tbody tr:hover {
  color: white;
  background: rgba(65, 105, 225, 90%);
}

.table-plan tfoot {
  background: royalblue;
  color: white;
}
</style>


<!-- Estilos formularios  -->

<style scoped>
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: royalblue;
}

.header,
thead {
  border-bottom: 1px solid #F0F0F0;
  background-color: #F7F7F7;
  padding: 20px 40px;
}

.header h2 {
  margin: 0;
  width: 100%;
}

#frmPrestamo {
  padding: 30px 40px;
}

#frmPrestamo .control,
#amortizaciones .control,
.radios {
  margin-bottom: 10px;
  padding-bottom: 20px;
  position: relative;
}

#frmPrestamo .control label {
  margin-bottom: 5px;
}

#frmPrestamo .control input,
#frmPrestamo .control select {
  border: 2px solid #F0F0F0;
  border-radius: 4px;
  font-family: inherit;
  font-size: 14px;
  padding: 10px;
  width: 100%;
}

#frmPrestamo .control input:focus {
  outline: 0;
  border-color: royalblue;
}

#frmPrestamo button {
  background: rgba(65, 105, 225, 90%);
  border: 2px solid royalblue;
  border-radius: 4px;
  color: #FFF;
  display: block;
  font-family: inherit;
  font-size: medium;
  padding: 10px;
  margin-top: 20px;
  width: 100%;
}

.radios {
  padding: 10px 20px;
}

.radioContenedor {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 30px;
  margin: 10px;
}

.radioContenedor input {
  display: none;
}

.radioContenedor .circle {
  left: 0;
  top: 0;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  position: absolute;
  display: inline-block;
  background-color: rgba(65, 105, 225, 90%);
  border-bottom: 2px solid royalblue;
}

.radioContenedor:hover .circle {
  background-color: royalblue;
}

.radioContenedor input:checked+.circle {
  background-color: royalblue;
}

.radioContenedor input:checked+.circle:after {
  content: '';
  height: 10px;
  width: 10px;
  background-color: white;
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.fa-star {
  color: #fff200;
}

.form-group {
  background-color: #f5f5f5f5;
  border-radius: 10px;
  margin-left: 10px;
  width: 97%;
}

p{
  margin: 0;
}

.btn{
  color: white;
}
</style>